import React, { Component } from "react";
import { Form, Select, Tooltip, Button, Row, Col, Input, DatePicker, Typography, Modal, Alert, Spin,message } from "antd";
import List from "../../grid.component";
import { validateContentRule } from "../../../utils/custom.validator";
import config from "../../../config";
import moment from 'moment';
import { introducerLookup, getFeeData, generateSave, deleteDetails, getSendDetails, getEmailDetails } from '../api';
import { connect } from "react-redux";
import apiCalls from "../../../api/apiCalls";

const { Option } = Select;
const { Title, Text } = Typography


class IntroducerReports extends Component {
    constructor (props) {
        super(props);
        this.state = {
            introducerLu: [],
            selectedIntroducerId: null,
            transactionFees: '',
            onboardingFees: '',
            gridUrl: process.env.REACT_APP_GRID_API + "Customer/IntroducerReports",
            selectedObj: {}, selection: [],
            searchObj: {
                timeSpan: "Last 1 Day",
                feature: "All Features",
                fromdate: '',
                todate: '',
            },
            customFromdata: "",
            customTodate: "",
            docPreviewModal: false,
            docPreviewDetails: null,
            selectedIntroducerName: null, btnLoader: false,
            isSend: false, sendData: {}, sendLoading: false, sendError: null, emailLoading: false,
            isDelete: false, deleteData: {}, deleteLoading: false, deleteError: null, isEmail: null,
            gridError: null

        };
        this.gridRef = React.createRef();
        this.formref = React.createRef();
    }

    componentDidMount() {
        this.getIntroducerLu();
    }

    gridColumns = [
        {
            field: "",
            title: "",
            width: 50,
            locked: true,
            customCell: (props) => (
                <label className="text-center custom-checkbox">
                    <input
                        id={props.dataItem.id}
                        name="check"
                        type="checkbox"
                        className="grid_check_box"
                        checked={this.state.selection?.indexOf(props.dataItem.id) > -1}
                        onChange={(e) => this.handleInputChange(props, e)}
                    />
                    <span></span>
                </label>
            ),
        },
        {
            field: "reportName",
            title: "Report Name",
            filter: true,
            locked: true,
            width: 210,
        },

        {
            field: "introducerUserName",
            title: "Introducer Name",
            filter: true,
            width: 210,
        },
        {
            field: "userCreated",
            title: "Created By",
            filter: true,
            width: 210,
        },
        { field: "createdDate", title: "Created Date", filterType: "date", isShowTime: true, filter: true, width: 210 },
        { field: "startDate", title: "Start Date", filterType: "date", isShowTime: true, filter: true, width: 210 },
        { field: "endDate", title: "End Date", filterType: "date", isShowTime: true, filter: true, width: 210 },
        {
            field: "financeCopy",
            title: "Finance Copy",
            filter: true,
            width: 240,
            customCell: (props) => {
                return (
                    <td>
                        <div
                            className="gridLink"
                            onClick={() => this.docFinanceCopyDownload(props?.dataItem?.financeCopy)}
                        >
                            {props?.dataItem?.financeFileName}
                        </div></td>
                );
            }
        },
        {
            field: "introducerCopy",
            title: "Introducer Copy",
            filter: true,
            width: 240,
            customCell: (props) => {
                return (
                    <td>
                        <div
                            className="gridLink"
                            onClick={() => this.docIntroducerCopyDownload(props?.dataItem?.introducerCopy)}
                        >
                            {props?.dataItem?.introducerFileName}
                        </div></td>
                );
            }
        },
        {
            field: "", title: "", width: 140,
            customCell: (props) => {
                return (
                    <td>
                        <Button
                            type="primary"
                            className="primary-btn"
                            onClick={() => this.handleSend(props?.dataItem)}
                        >
                            Send
                        </Button></td>
                );
            }
        },

    ];

    handleSend = async (data) => {
        this?.setState({ ...this?.state, isSend: true, sendData: data, isDelete: false, deleteData: {}, deleteError: null, emailLoading: true })
        let response = await getEmailDetails(data?.introducerId)
        if (response.ok) {
            this.setState({ ...this.state, isEmail: response.data?.email, emailLoading: false })
           
        }
        else {
            this.setState({ ...this.state, isEmail: null, sendData: {}, sendError: apiCalls?.isErrorDispaly(response), emailLoading: false })

        }

    }

    handleSendCancel = () => {
        this?.setState({ ...this?.state, isSend: false })
    }

    handleDelete = () => {
        if (this?.state?.selection.length > 1 || this?.state?.selection.length === 0) {
            this.setState({ ...this.state, gridError: "Please select the one record" });
            this.useDivRef?.current.scrollIntoView();
        } else {
            this?.setState({ ...this?.state, isDelete: true, gridError: null, isSend: false, sendError: null,selection:[] })
        }
    }
    handleDeleteCancel = () => {
        this?.setState({ ...this?.state, isDelete: false,selection:[] })
    }
    getDeleteData = async (data) => {
        this?.setState({ ...this?.state, deleteData: data, deleteLoading: true, sendError: null, isSend: false })
        let response = await deleteDetails(data?.id)
        if (response.ok) {
            this?.setState({ ...this?.state, isDelete: false, deleteLoading: false })
            this.gridRef.current.refreshGrid();
        }
        else {
            this?.setState({ ...this?.state, isDelete: false, deleteData: {}, deleteLoading: false, deleteError: apiCalls.isErrorDispaly(response) })

        }
    }
    getSendData = async (data) => {
        this?.setState({ ...this?.state, sendLoading: true, sendError: null })

        let response = await getSendDetails(data?.id)
        if (response.ok) {
            this?.setState({ ...this?.state, isSend: false, sendLoading: false, sendError: null })
            message.success({
                content: "Email sent successfully",
                className: "custom-msg",
                duration: 3
            });
            this.gridRef.current.refreshGrid();
        }
        else {
            this?.setState({ ...this?.state, isSend: false, sendData: {}, sendLoading: false, sendError: apiCalls?.isErrorDispaly(response) })

        }
    }

    docFinanceCopyDownload = (data) => {
        const pdfUrl = data;
        const link = document.createElement('a');
        link.href = pdfUrl;
        link.target = '_self';
        link.download = 'file.pdf';
        link.click();
    }
    docIntroducerCopyDownload = (data) => {
        const pdfUrl = data;
        const link = document.createElement('a');
        link.href = pdfUrl;
        link.target = '_self';
        link.download = 'file.pdf';
        link.click();
    }

    handleInputChange = (prop, e) => {
        const rowChecked = prop.dataItem;
        const target = e.target;
        const value = target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;
        let { selection } = this.state;
        let idx = selection.indexOf(rowChecked.id);
        if (selection) {
            selection = [];
        }
        if (idx > -1) {
            selection.splice(idx, 1);
        } else {
            selection.push(rowChecked.id);
        }
        this.setState({
            ...this.state,
            [name]: value,
            selectedObj: rowChecked,
            selection, error: null,
            warningMsg: null,
            errorMsg: null, sendError: null, deleteError: null, gridError: null
        });
    };
    getIntroducerLu = async (searchTerm) => {
        let response = await introducerLookup(searchTerm); // Modify API call to accept a search term if necessary
        if (response.ok) {
            this.setState({ ...this.state, introducerLu: response.data });
        } else {
            this.setState({ errorMsg: apiCalls?.isErrorDispaly(response) });
        }
    };


    getFeeDetails = async (introducerId) => {
        let response = await getFeeData(introducerId);
        if (response.ok) {
            this.setState({
                transactionFees: response.data.transactionFee || '--',
                onboardingFees: response.data.onBoardingFee || '--'
            });
            this.formref.current.setFieldsValue({
                transactionFees: response.data.transactionFee || '--',
                onboardingFees: response.data.onBoardingFee || '--'
            })
        }
    }


    handleIntroducerChange = (value) => {
        const selectedIntroducer = this.state.introducerLu.find(item => item.introducerName === value);
        this.setState({
            selectedIntroducerId: selectedIntroducer?.introducerId,
            selectedIntroducerName: value || null
        });
        this.getFeeDetails(selectedIntroducer?.introducerId);
    };


    handleDateChange = (prop, val) => {
        let { searchObj, customFromdata, customTodate } = this.state;
        searchObj[val] = new Date(prop);
        this.setState({ ...this.state, searchObj, fromdate: customFromdata, todate: customTodate });
    };

    saveDetails = async (values) => {       
        this.setState({ ...this.state, btnLoader: true ,errorMsg: null,sendError: null, deleteError: null, gridError: null})
        let obj = {
            "IntroducerId": this.state?.selectedIntroducerId,
            "IntroducerName": this.state?.selectedIntroducerName,
            "UserCreated": this.props.userConfig.userName,
            "StartDate": values?.fromdate,
            "createddate": new Date(),
            "EndDate": values?.todate,

        }
        let response = await generateSave(obj)
        if (response.ok) {
            this.setState({ ...this.state, btnLoader: false });
            message.success({
                content: "Report generated successfully",
                className: "custom-msg",
                duration: 3
            });
            this.formref.current?.resetFields();
            this.gridRef.current.refreshGrid();
        }
        else {
            this.setState({ ...this.state, errorMsg: apiCalls?.isErrorDispaly(response), btnLoader: false })
        }
    }
    handleSearch = (e, type) => {
        let sval = e.target.value;
        if (sval && sval.length >= 3) {
            if (type === "introducerName") {
                this.getIntroducerLu(sval);
            }
        }
        if(!sval){
            this.getIntroducerLu(null);
        }
    };


    render() {
        const { introducerLu, transactionFees, onboardingFees, searchObj } = this.state;
        return (
            <>
                <div ref={this.useDivRef}>
                    {this.state.errorMsg && <div style={{ width: '100%' }}>
                        <Alert className="mb-16 w-100" type="warning"
                            description={this.state.errorMsg} showIcon />
                    </div>}
                    <Form
                        ref={this.formref}
                        autoComplete="off"
                        onFinish={this.saveDetails}
                    >
                        <Row gutter={[16, 16]}>
                            <Col xs={24} sm={24} md={12} xl={6} xxl={6}>
                                <Form.Item
                                    label="Introducer Name"
                                    name="introducerName"
                                    className="input-label mb-0"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Is required",
                                        },
                                        {
                                            validator: validateContentRule,
                                        },
                                    ]}
                                >
                                    <Select
                                        className="cust-input"
                                        maxLength={100}
                                        placeholder="Select Introducer Name"
                                        dropdownClassName="select-drpdwn"
                                        onKeyUp={(e) => this.handleSearch(e, "introducerName")}
                                        onChange={this.handleIntroducerChange}
                                        showSearch
                                    >
                                        {introducerLu?.map((item, idx) => (
                                            <Option key={idx} value={item.introducerName}>
                                                {item.introducerName}
                                            </Option>
                                        ))}
                                    </Select>

                                </Form.Item>

                            </Col>
                            <Col xs={24} sm={24} md={12} xl={6} xxl={6}>
                                <Form.Item
                                    label="Transaction Fees %"
                                    name="transactionFees"
                                    className="input-label mb-0"
                                >
                                    <Input
                                        className="cust-input"
                                        maxLength={100}
                                        placeholder="Transaction Fees %"
                                        value={transactionFees || "--"}
                                        disabled={true}

                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} xl={6} xxl={6}>
                                <Form.Item
                                    label="Onboarding Fees %"
                                    name="onboardingFees"
                                    className="input-label mb-0"
                                >
                                    <Input
                                        className="cust-input"
                                        maxLength={100}
                                        placeholder="Onboarding Fees %"
                                        value={onboardingFees}
                                        disabled={true}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} xl={6} xxl={6}>
                                <Form.Item
                                    name="fromdate"
                                    className="input-label"
                                    label="Start Date"
                                    rules={[
                                        { required: true, message: "Is required" }
                                    ]}
                                >
                                    <DatePicker
                                        placeholder="Select Start Date"
                                        format={config?.dateFormates?.dateFormate}
                                        className="cust-input"
                                        onChange={(e) => this.handleDateChange(e, 'fromdate')}
                                        disabledDate={(current) => current && current > moment().endOf('day')}


                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} xl={6} xxl={6}>
                                <Form.Item
                                    name="todate"
                                    className="input-label"
                                    label="End Date"
                                    rules={[
                                        { required: true, message: "Is required" },
                                        {
                                            validator: (rule, value, callback) => {
                                                const { fromdate } = this.state.searchObj;
                                                if (value && fromdate) {
                                                    if (moment(value).isBefore(moment(fromdate))) {
                                                        callback("Start date must be less than or equal to the end date ");
                                                    } else {
                                                        callback();
                                                    }
                                                } else {
                                                    callback();
                                                }
                                            }
                                        }
                                    ]}
                                >
                                    <DatePicker
                                        className="cust-input"
                                        placeholder="Select End Date"
                                        format={config?.dateFormates?.dateFormate}
                                        onChange={(e) => this.handleDateChange(e, 'todate')}
                                        disabledDate={(current) => current && current > moment().endOf('day')}

                                    />
                                </Form.Item>

                            </Col>
                            <Col xs={24} sm={24} md={12} xl={4} xxl={4}>
                                <div className="mt-36">
                                    <Button type="primary" className="primary-btn" htmlType="submit" loading={this?.state?.btnLoader}>
                                        Generate
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                    {this.state.gridError !== null && <div style={{ width: '100%' }}>
                        <Alert className="mb-16 w-100" type="error" description={this.state.gridError} showIcon /></div>}
                    <div className="text-right mb-16">
                        <Tooltip title="Delete" >
                            <span className="icon c-pointer md delete mr-0 ant-tooltip-open" onClick={() => this.handleDelete()}></span>
                        </Tooltip>
                    </div>
                    <List
                        showActionBar={false}
                        pKey={"Receive Fiat"}
                        url={this.state.gridUrl}
                        key={this.state?.gridUrl}
                        ref={this.gridRef}
                        columns={this.gridColumns}
                        showExcelExport={true}
                    />

                    <Modal
                        visible={this.state?.isDelete}
                        title={"Confirm Delete"}
                        closeIcon={
                            <Tooltip title="Close">
                                <span
                                    className="icon md x c-pointer risk-close"
                                    onClick={this.handleDeleteCancel}
                                />
                            </Tooltip>
                        }
                        footer={
                            <Form.Item className="mb-0">
                                <div className="text-right">
                                    <Button
                                        type="primary"
                                        className="primary-btn cancel-btn mr-8"
                                        onClick={this.handleDeleteCancel}
                                    >
                                        {" "}
                                        No{" "}
                                    </Button>
                                    <Button
                                        type="primary"
                                        className="primary-btn"
                                        htmlType="submit"
                                        onClick={() => this.getDeleteData(this?.state?.selectedObj)}
                                        loading={this.state.deleteLoading}
                                    >
                                        Yes{" "}
                                    </Button>
                                </div>
                            </Form.Item>}>
                        {this.state.deleteError !== null && <div style={{ width: '100%' }}>
                            <Alert className="mb-16 w-100" type="error" description={this.state.deleteError} showIcon /></div>}

                        <p className="mb-0"> Are you sure, do you really want to delete ?</p>
                    </Modal>

                    <Modal
                        visible={this.state?.isSend}
                        title={"Confirm Send"}
                        closeIcon={
                            <Tooltip title="Close">
                                <span
                                    className="icon md x c-pointer risk-close"
                                    onClick={this.handleSendCancel}
                                />
                            </Tooltip>
                        }
                        footer={
                            <Form.Item className="mb-0">
                                <div className="text-right">
                                    <Button
                                        type="primary"
                                        className="primary-btn cancel-btn mr-8"
                                        onClick={this.handleSendCancel}
                                    >
                                        {" "}
                                        No{" "}
                                    </Button>
                                    <Button
                                        type="primary"
                                        className="primary-btn"
                                        htmlType="submit"
                                        onClick={() => this.getSendData(this?.state?.sendData)}
                                        loading={this.state.sendLoading}
                                    >
                                        Yes{" "}
                                    </Button>
                                </div>
                            </Form.Item>}>
                        {this.state.sendError !== null && <div style={{ width: '100%' }}>
                            <Alert className="mb-16 w-100" type="error" description={this.state.sendError} showIcon /></div>}
                        {this?.state?.emailLoading && <Spin size="small" />}
                        {!this?.state?.emailLoading && <>
                           
                            <p className="mb-0"> Are you sure, do you really want to Send to this email: <b>{this?.state?.isEmail}</b>  ?</p></>}
                    </Modal>

                </div>
            </>
        );
    }
}

const connectStateToProps = ({ userConfig }) => {
    return {
        userConfig: userConfig?.userProfileInfo
    };
};
export default connect(connectStateToProps)(IntroducerReports);
