import React, { useEffect, useState } from "react";
import {Button,Form,Col, Select, Alert,message, Empty,Input, Row,Modal} from "antd";
import { connect } from "react-redux";
import { getCommissionsData,getCommissionsLU,getCommissionTemplateData, saveCustomerFee } from "./api";
import Loader from "../loader.component";
import apiCalls from "../../api/apiCalls";
import Info from "../info/Info";
import NumberFormat from "react-number-format";
import ComissionCheckBoxModal from "../../utils/comissionCheckBoxModal";
const { Option } = Select;

const CustomerBatchFee = (props) => {
const [form] = Form.useForm();
const [loader,setLoader] = useState(false);
const [errorMsg, setErrorMsg] = useState(null);
const [commisionData,setCommssionData]=useState(null);
const [commissionsList, setCommissionsList] = useState([]);
const [commissionTemp,setCommissionTemp]=useState([]);
const [disable,setDisable]=useState(false);
const [isShowSaveBtn, setIsShowSaveBtn] = useState(false);
const [isCheckBoxModal, setIsCheckBoxModal] = useState(false);
const [checkBoxErrorMsg, setCheckBoxErrorMsg] = useState(null);
const [btnLoading, setBtnLoading] = useState(false);
const [commissionGroupList, setCommissionGroupList] = useState(null);
const [selctionCustomerNames, setSelctionCustomerNames] = useState([]);
const [inputFieldChange, setInputFieldChange] = useState(false);
const [cancelWarningVisible, setCancelWarningVisible] = useState(false);


    useEffect(()=>{
      if(props?.isBatchEdit){
        setErrorMsg(null);
        listEmpty();
        selectionObjects();
        getCommisionTemp();
      }else {
        getCommisionTemp(props?.params?.match?.params?.id);
        getCommission();
        setErrorMsg(null);
      }
    },[props?.activeTab,props?.selectionIds])  // eslint-disable-line react-hooks/exhaustive-deps

    const selectionObjects=()=>{
      let selectionNames = [];
      props?.selectionObjects?.map((item)=>{
          if(item.id){
            selectionNames.push(item?.accountType === "Business" ? item?.businessName : (item?.firstName + " "+item?.lastName));
          }
      })
      setSelctionCustomerNames(selectionNames);
    }

    const getCommission=async(commissionId)=>{
      setErrorMsg(null);
      setCheckBoxErrorMsg(null);
      setLoader(true);
        let response= commissionId ? await getCommissionTemplateData(commissionId) :
        await getCommissionsData(props?.params?.match?.params?.id);
      if(response.ok){
        setErrorMsg(null);
        setCheckBoxErrorMsg(null);
        setCommssionData(response.data);
        setCommissionsList(response.data?.commissionDetails);
        let products=response.data?.commissionDetails;
        if(products) {
          setErrorMsg(null);
        setCheckBoxErrorMsg(null);
          const groupByCategory = products?.reduce((group, product) => {
            const { operation } = product;
            group[operation] = group[operation] ?? [];
            group[operation].push(product);
            return group;
          }, {});
          const tableData = Object.entries(groupByCategory);
          setCommissionGroupList(tableData)
        }
        if(response.data?.commissionDetails && response.data?.commissionDetails?.length > 0) {
          setDisable(true)
        }else{
          setDisable(false)
        }
        setLoader(false);
        setIsShowSaveBtn(false);
        form?.setFieldsValue({ templateName: response.data?.templateName });
      }
      else {
        setLoader(false);
        setErrorMsg(apiCalls.isErrorDispaly(response))
        window.scrollTo(0, 0)
      }
    }

    const listEmpty=()=>{
      form?.setFieldsValue({ templateName: null });
      setCommssionData(null);
      setCommissionsList([]);
      setCommissionGroupList(null);
    }

    const getCommisionTemp=async(customerId)=>{
      setErrorMsg(null);
      setCheckBoxErrorMsg(null);
      if(props?.isBatchEdit)setLoader(true);
      let res=await getCommissionsLU(customerId);
     if(res.ok){
       setErrorMsg(null);
      setCheckBoxErrorMsg(null);
      setCommissionTemp(res.data);
      if(props?.isBatchEdit)setLoader(false);
     }
     else{
      setErrorMsg(apiCalls.isErrorDispaly(res));
      window.scrollTo(0, 0);
      if(props?.isBatchEdit)setLoader(false);
     }
    }

    const handleEdit = async()=>{
      window.scroll(0,0);
      form?.setFieldsValue({ templateName: "Customized" });
      setDisable(false);
      setIsShowSaveBtn(true);
    }

  const handleChange = (e, item, key) => {
    setInputFieldChange(true);
    if(e.target.value){
      let value=parseFloat(e.target.value?.replace(/,/g, ''));
      commissionDataEdit(item, key, value);
    }else{
      commissionDataEdit(item, key, e.target.value);
    }
  }

  const commissionDataEdit =(item,key,value) => {
    if(commissionsList?.length>0){
    let data = commissionsList?.find((product) => product === item)
    let _obj = data;
    _obj[key] = value;
    let _objData = [_obj]
    commissionsList?.map(obj => _objData.find(o => o.id === _obj.id) || obj);
  }}
    
  const handleCheck = (e, item, key) => {
    setInputFieldChange(true);
    if(commissionsList?.length>0) {
    const value = e.target.checked;
    let data = commissionsList?.find((product) => product === item)
    let _obj = data;
    _obj[key] = value;
    let _objData = [_obj]
    commissionsList?.map(obj => _objData.find(o => o.id === _obj.id) || obj);
    }
  }

  const handleTemplateChange = (e) => {
    const commissionTempfil=commissionTemp.filter(item=>item.templateName===e);
    listEmpty();
    getCommission(commissionTempfil[0]?.id);

  }

    const handleCancel = () => {
      setIsCheckBoxModal(false);
      setCheckBoxErrorMsg(null);
      setBtnLoading(false);
    }

    const CommissionFeeSave=async ()=>{
      setErrorMsg(null);
      setCheckBoxErrorMsg(null);
      setBtnLoading(true);
        let saveObj = Object.assign({}, commisionData);
        saveObj.id = commisionData?.id || props?.params?.match?.params?.id;
        saveObj.customerId = props?.params?.match?.params?.id || props?.userConfig?.id;
        saveObj.customerIds = props?.isBatchEdit ?  props?.selectionIds : [props?.params?.match?.params?.id] || commisionData?.customerId;
        saveObj.templateName = props?.isBatchEdit ? commisionData?.templateName : "Customized";
        saveObj.userCreated = props.userConfig?.userName;
        saveObj.createdDate = new Date();
        saveObj.modifiedBy = props.userConfig?.userName;
        saveObj.modifiedDate = new Date();
        saveObj.info = JSON.stringify(props?.trackAuditLogData);
        saveObj.status = commisionData?.status||"Active";
        saveObj.commissionDetails = commissionsList;
        let response = await saveCustomerFee(saveObj);
        if (response.ok) {
          message.destroy();
          setBtnLoading(false);
          setDisable(true);
          setInputFieldChange(false);
          setCancelWarningVisible(false);
          setCheckBoxErrorMsg(false);
          setErrorMsg(null);
          message.success({
            content: props?.isBatchEdit ? 'Batch Edit details saved successfully' : 'Commission fee details saved successfully',
            className: "custom-msg",
            duration: 3
          })
          form.resetFields();
          if(props?.isBatchEdit) {
            listEmpty();
           props?.onBatchEditClose();
          }else {
            getCommission();
            setErrorMsg(null);
            setCheckBoxErrorMsg(null);
          }
          }else {
        
        if(response.data.title=="Commissions not selected for one or more fields"){
          setIsCheckBoxModal(true);
          setBtnLoading(false);
          setCheckBoxErrorMsg("Commissions not selected for one or more fields.")
         }else if(response.data.title=="Please enter value in selected respective section"){
          setIsCheckBoxModal(true);
          setBtnLoading(false);
          setCheckBoxErrorMsg("Please enter value in selected respective section.")
         }else{
          window.scrollTo(0, 0)
          setBtnLoading(false);
          setErrorMsg(apiCalls.isErrorDispaly(response))
         }
    }
  }

    const handleCancelBtn = () => {
      if(props?.isBatchEdit){
        props?.onBatchEditClose();
      }else{
        if(inputFieldChange){
          setCancelWarningVisible(true);
        }else{
           props?.params?.history?.push("/customers")
        }
      }
    }

    const handleBackToCommission = () => {
      setCancelWarningVisible(false);
    }

    const hadleCommission = () => {
      setCancelWarningVisible(false);
      props?.params?.history?.push("/customers");
    }

    return (
     
          <>
           {loader && <Loader />}
           {errorMsg !== null && (
          <Alert
            className="w-100 mb-16"
            type="error"
            description={errorMsg}
            showIcon
          />
        )}
        {selctionCustomerNames?.length > 0 &&
          <div>{selctionCustomerNames?.join(", ")}</div>
        }
        {(commisionData||props?.isBatchEdit)&&
        <>
                  <Form autoComplete="off" className="commission-form" form={form} onFinish={CommissionFeeSave}
                  initialValues={commisionData}>
                    <div className="cust-commission-br">
                      <Row className="edit-section">
                  <Col sm={24} xs={12} md={6} className="px-8">
                <Form.Item
                  name="templateName"
                  className="input-label my-16"
                  label={props?.isBatchEdit ? "Batch Edit to" :"Commission Template"}
                >
                  <Select
                    className={`cust-input w-80 
                    ${(!isShowSaveBtn && !props?.isBatchEdit && props?.params?.match?.params?.type !== 'view' && commissionGroupList) && "cust-input-disabled"}`}
                    disabled={!props?.isBatchEdit?disable:false}
                    onSelect={(e)=>handleTemplateChange(e)}
                    placeholder="Select Commission Template"
                    value
                  >
                    {commissionTemp?.map((item, idx) => (
                      <Option key={idx} value={item.templateName} title="">
                        {item.templateName}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>    
              <Col sm={24} xs={12} md={18} className="text-right px-8">
              {(!isShowSaveBtn && !props?.isBatchEdit && props?.params?.match?.params?.type !== 'view' && commissionGroupList)&& <div className="text-right mt-16 mb-16">
              <Button
                type="primary"
                className="primary-btn"
                onClick={handleEdit}
              >
                Edit
              </Button>
            </div>}
              </Col>
              </Row>
              <div className="addcommission">             
              <table className="commision-table table-border edit-commition-table table-responsive" border="1">
                <thead><tr className="table-header-row">
                      <th style={{ width: "150px" }}></th>
                      <th className="p-0">
                        <table className="table-partner-head">
                          <tr>
                            <th colSpan={2} className="text-center">SuisseBase Fees</th>
                            <th className="table-border-right"></th>
                            <th style={{ width: "150px" }} className="table-border-right text-center" >Partner</th>
                            <th style={{ width: "150px" }} className="table-border-right text-center">Sub-Partner</th>
                          </tr>
                        </table>
                      </th>
                   
                  </tr></thead>
               <tbody>
                {commissionGroupList?.map(([operation,items])=>(
                    <tr key={operation}>
                    <td style={{ width: "100px" }}>{operation}</td>
                    <td style={{padding:"0"}}>
                        {items?.map((item) => (<>
                          <table className="row-border " key={item?.id}>
                            <tr key={item?.id}>

                              <td style={{ borderRight: "1px solid #ccc",width:"200px" }}>
                                <table>
                                  {item.currencyType&&<tr>
                                  <td>{item.currencyType}</td>
                                  </tr>}
                                  {item.currency&&<tr>
                                  <td>{item.currency}</td>
                                  </tr>}
                                </table>
                              </td>
                              


                              <td className="inside" style={{width:"200px"}} >{item.bankName}
                              <div>
                              {" "} {item.isPersonalBank && <span className="file-labels  fs-12 address-label address-label-width">Personal</span>}
                              {" "} 
                              {item.status==="Inactive" && 
                              <span className="file-labels fs-12 address-label address-label-width in-active">Inactive</span>
                               } 
                                {item.type === "Card" &&
                                      <span className="file-labels  fs-12 address-label address-label-width">Card</span>
                                    }
                              </div>
                              </td>
                              <td  className=" inside" >
                              <tr>
                              <td >
                                <Form.Item className="customised-input" name="minFee" style={{ width: "200px" }}>
                                  <div className="d-flex align-center mr-8">
                                    <label className="text-center custom-checkbox">
                                      <input name="check" type="checkbox" onChange={(e) => handleCheck(e, item, "isMinMax")} defaultChecked={item?.isMinMax} disabled={(commisionData?.templateName?.toLowerCase() !=="customized" ||item?.status === "Inactive") && (disable||item?.status === "Inactive")}/>
                                      <span></span>{" "}
                                    </label>
                                  </div>
                                  <NumberFormat
                                    decimalScale={2}
                                    customInput={Input}
                                    className="cust-input"
                                    onChange={(e) => handleChange(e, item, "minFee")}
                                    thousandSeparator={true}
                                    allowNegative={false}
                                    maxLength={6}
                                    value={item?.minFee}
                                    addonAfter={<span>Min</span>}
                                    disabled={(commisionData?.templateName?.toLowerCase() !=="customized" ||item?.status === "Inactive") && (disable||item?.status === "Inactive")}
                                  />
                                </Form.Item>
                                </td>
                               
                              <td >
                                
                                <Form.Item className="customised-input" name="maxFee" style={{ width: "200px" }}>
                                <div>
                                  <NumberFormat
                                    decimalScale={2}
                                    customInput={Input}
                                    className="cust-input"
                                    onChange={(e) => handleChange(e, item, "maxFee")}
                                    thousandSeparator={true}
                                    allowNegative={false}
                                    maxLength={6}
                                    value={item?.maxFee}
                                    addonAfter={<span>Max(%)</span>}
                                    disabled={(commisionData?.templateName?.toLowerCase() !=="customized" ||item?.status === "Inactive") && (disable||item?.status === "Inactive")}
                                  />
                                   </div>
                                </Form.Item>
                               
                              </td>
                             
                              </tr>
                              <tr>
                              <td >  <Form.Item className="customised-input" name="flatFee" style={{ width: "200px" }}>
                                <div className="d-flex align-center mr-8">
                                  <label className="text-center custom-checkbox">
                                    <input name="check" type="checkbox" onChange={(e) => handleCheck(e, item, "isFlat")} defaultChecked={item?.isFlat} disabled={(commisionData?.templateName?.toLowerCase() !=="customized"|| item?.status === "Inactive") && (disable||item?.status === "Inactive")}/>
                                    <span></span>{" "}
                                  </label>

                                </div>
                                <NumberFormat
                                  decimalScale={2}
                                  customInput={Input}
                                  className="cust-input"
                                  onChange={(e) => handleChange(e, item, "flatFee")}
                                  thousandSeparator={true}
                                  allowNegative={false}
                                  maxLength={6}
                                  value={item?.flatFee}
                                  addonAfter={<span>Flat</span>}
                                  disabled={(commisionData?.templateName?.toLowerCase() !=="customized" ||item?.status === "Inactive") && (disable||item?.status === "Inactive")}
                                />
                              </Form.Item></td>
                              </tr>
                              
                                <tr>
                                  {item.isPersonalBank && <>
                                  <td style={{ width: "150px" }} className="commition-input-bg onetime-monthly">
                                   
                                      <Form.Item className="customised-input" name="oneTimeFee" style={{ width: "200px" }}>
                                        <div className="d-flex align-center mr-8">
                                          <label className="text-center custom-checkbox">
                                            <input name="check" type="checkbox" onChange={(e) => handleCheck(e, item, "isOneTime")} defaultChecked={item?.isOneTime} disabled={(commisionData?.templateName?.toLowerCase() !=="customized"|| item?.status === "Inactive") && (disable||item?.status === "Inactive")}/>
                                            <span></span>{" "}
                                          </label>
                                        </div>
                                        <NumberFormat
                                          decimalScale={2}
                                          customInput={Input}
                                          className="cust-input"
                                          onChange={(e) => handleChange(e, item, "oneTimeFee")}
                                          thousandSeparator={true}
                                          value={item.oneTimeFee}
                                          allowNegative={false}
                                          maxLength={6}
                                          disabled={(commisionData?.templateName?.toLowerCase() !=="customized"||item?.status === "Inactive") && (disable||item?.status === "Inactive")}
                                          addonAfter={<span>One Time</span>}
                                        />
                                      </Form.Item>
                              </td>
                              <td style={{ width: "150px" }} className="commition-input-bg onetime-monthly">
                             
                                <Form.Item className="customised-input" name="monthlyFee" style={{ width: "200px" }}>
                                  <div className="d-flex align-center mr-8">
                                    <label className="text-center custom-checkbox">
                                      <input name="check" type="checkbox" onChange={(e) => handleCheck(e, item, "isMonthly")} defaultChecked={item?.isMonthly} disabled={(commisionData?.templateName?.toLowerCase() !=="customized"||item?.status === "Inactive" )&& (disable||item?.status === "Inactive")}/>
                                      <span></span>{" "}
                                    </label>
                                  </div>

                                  <NumberFormat
                                    decimalScale={2}
                                    customInput={Input}
                                    className="cust-input"
                                    onChange={(e) => handleChange(e, item, "monthlyFee")}
                                    thousandSeparator={true}
                                    allowNegative={false}
                                    maxLength={6}
                                    value={item?.monthlyFee}
                                    disabled={(commisionData?.templateName?.toLowerCase() !=="customized" ||item?.status === "Inactive") && (disable||item?.status === "Inactive")}
                                    addonAfter={<span>Monthly</span>}
                                  />
                                </Form.Item>

                              </td>
                              </>}
                              </tr>
                              </td>

                              <td style={{ width: "150px" }} className="inside">
                                <Form.Item className="input-addon-style customised-input" name="partnerPercentage">
                                  <div>
                                    <NumberFormat
                                      decimalScale={2}
                                      customInput={Input}
                                      className="cust-input"
                                      onChange={(e) => handleChange(e, item, "partnerPercentage")}
                                      thousandSeparator={true}
                                      allowNegative={false}
                                      maxLength={6}
                                      defaultValue={item?.partnerPercentage}
                                      disabled={(commisionData?.templateName?.toLowerCase() !=="customized" ||item?.status === "Inactive") && (disable||item?.status === "Inactive")}
                                      addonAfter={<span>%</span>}
                                    />
                                  </div>

                                </Form.Item>
                              </td>


                              <td style={{ width: "150px" }} className="inside">
                                <Form.Item className="input-addon-style customised-input" name="subPartnerPercentage">
                                  <div>
                                    <NumberFormat
                                      decimalScale={2}
                                      customInput={Input}
                                      className="cust-input"
                                      onChange={(e) => handleChange(e, item, "subPartnerPercentage")}
                                      thousandSeparator={true}
                                      allowNegative={false}
                                      maxLength={6}
                                      defaultValue={item?.subPartnerPercentage}
                                      disabled={(commisionData?.templateName?.toLowerCase() !=="customized" ||item?.status === "Inactive") && (disable||item?.status === "Inactive")}
                                      addonAfter={<span>%</span>}
                                    />
                                  </div>
                                </Form.Item>
                              </td>
                            </tr>
                          </table>
                        </>
                        ))}
                      </td>
                    </tr>
                  
                ))}
               </tbody>
                </table>
            {
              (commissionsList?.length === 0 || !commissionGroupList) && 
                <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description="No Data Found"></Empty>
               
            }
            </div> 
                    </div>
          {!props?.isBatchEdit && <><Info
            Id={commisionData?.id}
            Status={commisionData?.status}
            Createdby={commisionData?.templateName?.toLowerCase() !=="customized" && commisionData.userCreated}
            CreatedDate={commisionData?.templateName?.toLowerCase() !=="customized" && commisionData.createdDate}
            ModifiedDate={commisionData?.modifiedDate}
            Modifiedby={commisionData?.modifiedBy}
            isShowInfo={false}
            screenName={"Customers"}
            infoDetails={true}
          /></>}
              <div className="text-right mt-24">
                {(((isShowSaveBtn && props?.params?.match?.params?.type !== 'view' && commissionGroupList) || (props?.isBatchEdit && commissionGroupList))||(commisionData?.templateName?.toLowerCase() ==="customized")) && <Button
                  type="primary"
                  className="primary-btn"
                  htmlType="submit"
                  style={{ marginLeft: 10 }}
                  loading={btnLoading}
                >
                  Save
                </Button>
                }
                <Button
                  type="primary"
                  className="primary-btn cancel-btn"
                  style={{ margin: '0 8px' }}
                  onClick={() => handleCancelBtn()
                  }
                >
                  Cancel
                </Button>
              </div>

              <Modal title="Confirm"
  visible={cancelWarningVisible}
  closable={false}
  footer={<>
    <Button
      type="primary"
      className="primary-btn cancel-btn"
      onClick={() => handleBackToCommission()}
    >
      No
    </Button>
    <Button
      type="primary"
      className="primary-btn"
      onClick={() => hadleCommission()}
      
    >
      Yes
    </Button>
  </>}>
  <h4 className="text-white-30 fs-16 fw-400">Do you want to cancel, your changes may not be save ?</h4>
</Modal>
         
        </Form>
        </>}
        {!commisionData&&!props?.isBatchEdit&&!commissionGroupList&&<>
             <div className="text-center mt-24"><p>Fee details are not available</p></div>
        </>}
             {isCheckBoxModal && <ComissionCheckBoxModal showModal={isCheckBoxModal} handleCloseModal={handleCancel} errorMessage={checkBoxErrorMsg}></ComissionCheckBoxModal>}
             </>
          )};


   


const connectStateToProps = ({ userConfig }) => {
  return { userConfig: userConfig.userProfileInfo, trackAuditLogData: userConfig.trackAuditLogData }
}
const connectDispatchToProps = (dispatch) => {
  return {
    dispatch
  };
};
export default connect(connectStateToProps,connectDispatchToProps)(CustomerBatchFee);