import React, { Component } from "react";
import List from "../grid.component";
import { Tooltip, Modal, message, Button, Alert, Input, Form, Row, Col, Select} from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import Loader from "../loader.component";
import apiCalls from "../../api/apiCalls";
import { connect } from "react-redux";
import { setBreadcrumb } from "../../reducers/breadcrumbReducer";
import DocumentsList from "../documents.component/documentsList";
import { sendRequest, uuidv4 } from "../documents.component/api";
import { setCurrentAction } from "../../reducers/actionsReducer";
import { updateCurrentScreen } from "../../reducers/permissionsReducer";
import {getAccountLu, getNameLu, getVerification,saveVerification, ApproveCard, saveLockUnlock,changeEmail,
  changePhoneNo, activeInactive} from "./api";
import { validateContentRule } from "../../utils/custom.validator";
import CreateCase from "../../utils/createCase";
import { error} from "../../utils/messages";
import { CaseRediractions } from '../../utils/caseRediractions';
import CustomerStateChange from "./state.change";
import RiskDetialsPage from "./riskDetailsPage"
import SuisseBaseCredits from '../../utils/suissebaseCredits'
import CustomerBatchFee from "./customerBatchFee";
import VerificationsModal from "../banks.component/verificationsModal";
import { phoneControlCodes } from "./phoneCode";
const { Option } = Select;
const { TextArea } = Input

class Customers extends Component {
  docReqForm;
  cardFormRef;
  constructor (props) {
    super(props);
    this.state = {
      isLoading: false,
      isVerLoading: false,
      isLockLoading: false,
      isPassLoading: false,
      isBtnLoading: false,
      warningMsg: null,
      vererrorMsg: null,
      btnDisabled: false,
      check: false,
      selectedObj: {},
      selectedObjs: [],
      selection: [],
      modal: false,
      caseModal: false,
      docModal: false,
      passwordModal: false,
      accounttypeData: [],
      nameData: [],
      isModalOpen: false,
      isPhoneNoChange:null,
      isEmailInput:null,
      isEmailLoading:false,
      emailModal:false,
      verificationsModalIsOpen:false,
      phoneNoChangeModal:false,
      phoneNoChangeModalIsOpen:false,
      emailExist : false,
      emailButtonLoader:false,
      phoneNoButtonLoader:false,
      FilteredCodeCountries:[],
      searchObj: {
        customerType: "All",
        memberName: "00000000-0000-0000-0000-000000000000"
      },
      accountObj: null,
      gridUrl: process.env.REACT_APP_GRID_API + "Customer",
      obj: {
        id: [],
        TableName: "Member.Customer",
        modifiedBy: "",
        status: []
      },
      errorMessage: "",
      documentRequest: {
        id: uuidv4(),
        transactionId: null,
        adminId: this.props?.userConfig?.id,
        date: new Date(),
        type: "Customer",
        customerId: null,
        note: "",
        remarks: "",
        details: []
      },
      companyModal: false,
      lockModal: false,
      informationModal: false,
      sendDisable: false,
      customerNames: [],
      emailCheck: false,
      phoneCheck: false,
      kycCheck: false,
      checkObj: {},
      verifyObj: {
        customerId: [],
        isPhoneverified: false,
        isEmailverified: false,
        isKyc: false
      },
      disableObj: {},
      stateLoading: true,
      saveDisable: false,
      isdiabledLoading: false,
      riskModal:false,suisseBaseCreditvisible:false,
      batchEditModal: false,
      phoneErrorMsg:null
    };
    this.gridRef = React.createRef();
    this.useDivRef = React.createRef();
    this.editCustomers = this.editCustomers.bind(this);
  }
  gridColumns = [
    {
      field: "",
      title: "",
      width: 50,
      locked: true,
      customCell: (props) => (
        <td>
          <label className="text-center custom-checkbox">
            <input
              id={props.dataItem.id}
              name="check"
              type="checkbox"
              checked={this.state.selection.indexOf(props.dataItem.id) > -1}
              onChange={(e) => this.handleInputChange(props, e)}
            />
            <span></span>
          </label>
        </td>
      )
    },
    {
      field: "firstName",
      title: "First Name",
      filter: true,
      locked: true,
      width: 200,
      customCell: (props) => (
        <>
          {props?.dataItem?.accountType === "Personal" ?
            <div className="gridLink" onClick={() => this.update(props)}>
              {props?.dataItem?.firstName}
            </div> : <div>{" "} </div>}
        </>
      )
    },
    {
      field: "lastName", title: "Last Name", filter: true, width: 200, customCell: (props) => (
        <td>
          {props?.dataItem?.accountType === "Personal" ?
            <div className="gridLink" onClick={() => this.update(props)}>
              {props?.dataItem?.lastName}
            </div> : <div>{props?.dataItem?.lastName} </div>}
        </td>
      )
    }, 
    { field: "customerState", title: "State", filter: true, width: 200 },
    { field: "introducer", title: "Introducer", filter: true, width: 130,dataType: "boolean", filterType: "boolean", },

    {
      field: "businessName", title: "Business Name", filter: true, width: 200,
      customCell: (props) => (
        <td>
          <div className="gridLink" onClick={() => this.update(props)}>
            {props?.dataItem?.businessName}
          </div>
        </td>
      )
    },   
    {
      field: "tradingVolume", title: "30 Day Trading Volume", filter: true, width: 240, 
      dataType: "number",
      filterType: "numeric"
    }, 
    {
      field: "commissionTemplate", title: "Commission Template", filter: true, width: 250 
    }, 
    {
      field: "credit", title: "SuisseBase Credit Balance", filter: true, width: 240,
      dataType: "number",
      filterType: "numeric" 
    }, 
    {
      field: "depositRefrenceId",
      title: "Customer/Contract ID",
      filter: true,
      width: 250
    },
    {
      field: "registerdate",
      title: "Reg Date",
      filter: true,
      filterType: "date",
      width: 150
    },
    {
      field: "caseids",
      title: "Case Number",

      width: 160,
      customCell: (props) => (
        <td>
          <div className="gridLink" >
            {props.dataItem.caseIds?.map(item => <div onClick={() => this.createCaseView(item)}>{item.caseNumber}</div>)}
          </div>
        </td>
      ),
    },

    { field: "accountType", title: "Account Type", filter: true, width: 200 },
    { field: "email", title: "Email", filter: true, width: 250 },
    { field: "phoneNo", title: "Phone Number", filter: true, width: 150 },
    {
      field: "isEmialVerified",
      title: "Email Verified",
      filter: true,
      width: 150
    },
    {
      field: "isPhoneNoVerified",
      title: "Phone Verified",
      filter: true,
      width: 150
    },
    { field: "isKYC", title: "KYC Compliance", filter: true, width: 160 },
    { field: "isKYB", title: "KYB Compliance", filter: true, width: 160 },
    {
      field: "amountThreshold",
      title: "Amount Threshold",
      filter: true,
      width: 180,
      dataType: "number",
      filterType: "numeric"
    },
    {
      field: "accumulatedDeposit",
      title: "Accumulated Deposit",
      filter: true,
      width: 180,
      dataType: "number",
      filterType: "numeric"
    },
    { field: "sbStaff", title: "SB Staff", filter: true, dataType: "boolean", filterType: "boolean", width: 130 },
    {field:"bankVerification",title:"Bank Verification",filter:true,dataType:"boolean",filterType:"boolean",width:170},
    {field:"IBAN",title:"IBAN",filter:true,dataType:"number",filterType:"numeric",width:100},
    {field:"initialDepositVerification",title:"Initial Deposit Verification",filter:true,dataType:"boolean",filterType:"boolean",width:240},
    {field:"amountVerified",title:"Amount Verified",filter:true,dataType:"boolean",filterType:"boolean",width:170},
    {field:"accountmanager",title:"Account Manager",filter:true,width:200},
    
    {field:"isTopUp",title:"My Card",filter:true,dataType: "boolean", filterType: "boolean", width: 130},

  ];
  formref = React.createRef();
  componentDidMount = () => {
    this.accountTypeSearch();
    this.getNameLookup(null, null);
    this.props.dispatch(updateCurrentScreen("customers"));
    this.phonecodeOptions();
  };
  success = (msg) => {
    message.destroy();
    message.success({
      content: msg,
      className: "custom-msg",
      duration: 3
    });
  };
 
   phonecodeOptions = () =>{
    let optionsphone = [];
    for(var i in phoneControlCodes){
      optionsphone.push({label:`${phoneControlCodes[i].name}(${phoneControlCodes[i].code})`,value:phoneControlCodes[i].code});
    }
  this.setState({...this.state,FilteredCodeCountries:[...optionsphone]});
  }

  hideCaseModal = () => {
    this.setState({ ...this.state, caseModal: false, selection: [], selectedObjs: [] }, () => this.gridRef.current.refreshGrid());
  }

  update = (e) => {
    this.props.dispatch(setCurrentAction(null));
    const items = e.dataItem;
    const val = items.id;
    if(items.status === "Inactive"){
      this.props.history.push("/customers/details/" + val + "/view/disabled");
    }else{
      this.props.history.push("/customers/details/" + val + "/view");
    }    
    this.props.dispatch(
      setBreadcrumb({
        key: "/customers/details/" + val,
        val: items.accountType !== "Business" ? (items.firstName + " " + items.lastName) : items.businessName
      })
    );


  };
  Information = async () => {

    this.setState({ ...this.state, stateLoading: true });
    const { selection } = this.state;
		if (selection.length === 0 || selection.length > 1) {
      this.setState({ ...this.state, warningMsg: selection.length > 1?"Please select only one record": 'Please select the one record',selection: []})
    } else {
      this.setState({ informationModal: true, saveDisable: false, warningMsg: null });
      let response = await getVerification(this.state.selectedObj.id);
      if (response.ok) {
        this.setState({
          ...this.state,
          stateLoading: false,
          phoneCheck: response.data.isPhoneverified,
          emailCheck: response.data.isEmailverified,
          kycCheck: response.data.isKyc,
          disableObj: response.data,
        });
      } else {
        error(response.data);
      }
    }
  };
  lockUnlock = async () => {
    const { selection } = this.state;
		if (selection.length === 0 || selection.length > 1) {
      this.setState({ ...this.state, warningMsg: selection.length > 1?"Please select only one record": 'Please select the one record',selection: [] })
    } else {
      this.setState({ lockModal: true, saveDisable: false, warningMsg: null, stateLoading: true, check: false });
    }

  }
  handleCheckChange = (e, type) => {
    if (type === "emailCheck") {
      this.setState({
        ...this.state,
        emailCheck: e.target.checked,
        verWarningMsg: null,
        warningMsg: null
      });
    } else if (type === "phoneCheck") {
      this.setState({
        ...this.state,
        phoneCheck: e.target.checked,
        verWarningMsg: null
      });
    } else if (type === "kycCheck") {

      this.setState({
        ...this.state,
        kycCheck: e.target.checked,
        verWarningMsg: null
      });
    }

  };
  saveInformation = async () => {
    this.setState({ ...this.state, saveDisable: true, isVerLoading: true })
    const { emailCheck, kycCheck, phoneCheck } = this.state;
    let verifyCheck = this.state.verifyObj;
    verifyCheck.customerId = this.state.selectedObj.id;
    verifyCheck.isPhoneverified = phoneCheck;
    verifyCheck.isEmailverified = emailCheck;
    verifyCheck.modifiedBy = this.props.userConfig?.userName;
    verifyCheck.isKyc = kycCheck;
    if (emailCheck || kycCheck || phoneCheck === true) {

      let response = await saveVerification(verifyCheck);
      if (response.ok) {
        this.success("Verification details saved successfully");
        this.setState({
          informationModal: false,
          selection: [],
          selectedObjs: [],
          check: false,
          emailCheck: true,
          kycCheck: true,
          phoneCheck: true,
          saveDisable: false,
          warningMsg: null,
          vererrorMsg: null,
          verWarningMsg: null,
          isVerLoading: false
        });
        this.gridRef.current.refreshGrid();
      } else {
        this.setState({
          ...this.state,
          isVerLoading: false,
          isBtnLoading: false,
          selection: [],
          selectedObjs: [],
          isCheck: false,
          btnDisabled: false,
          vererrorMsg: apiCalls.isErrorDispaly(response),
          errors: apiCalls.isErrorDispaly(response),
          informationModal: true, check: false
        });
       
      }
     
    }

    else {
     
      this.setState({ ...this.state, saveDisable: false, verWarningMsg: 'Please select only one record', isVerLoading: false });
      

    }
  };
  

  saveLock = async () => {
    this.setState({ ...this.state, saveDisable: true, isLockLoading: true })
    let response = await saveLockUnlock(this.state.selectedObj.id, this.state.selectedObj.isLocked ? "Unlock" : "Lock", this.props?.userConfig?.id);
    if (response.ok) {
      this.success(
        this.state.selectedObj.isLocked ?
          "Customer unlocked successfully" : "Customer locked successfully"
      );
      this.gridRef.current.refreshGrid();
      this.setState({ ...this.state, lockModal: false, isLockLoading: false, selection: [],selectedObjs: [] })

    } else {
      this.setState({
        ...this.state,
        vererrorMsg: apiCalls.isErrorDispaly(response),
        isLockLoading: true, lockModal: true, selection: [], selectedObjs: []
      })
    }
  }

  statusUpdate = () => {
    const { selection } = this.state;
		if (selection.length === 0 || selection.length > 1) {
      this.setState({ ...this.state, warningMsg: selection.length > 1?"Please select only one record": 'Please select the one record',selection: [],selectedObjs:[] });
    } else {
      this.setState({ modal: true, warningMsg: null });
    }
  };
  resetPassword = () => {
    const { selection } = this.state;
		if (selection.length === 0 || selection.length > 1) {
      this.setState({ ...this.state, warningMsg:selection.length > 1?"Please select only one record": 'Please select the one record',selection: [],selectedObjs:[] });
    } else {
      this.setState({ ...this.state, passwordModal: true, warningMsg: null });
    }
 
  };
  handleInputChange = (event, i) => {
    const rowChecked = event.dataItem;
    const target = i.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    let { selection, selectedObjs } = this.state;
    let idx = selection.indexOf(rowChecked.id);
    if (idx > -1) {
      selection.splice(idx, 1);
      selectedObjs.splice(idx, 1);
    } else {
      selection.push(rowChecked.id);
      selectedObjs.push(rowChecked);
    }
    this.setState({
      ...this.state,
      [name]: value,
      selectedObj: (value) ? rowChecked : selectedObjs[0],
      cardState: rowChecked?.cardStatus,
      selection: selection,
      selectedObjs,
      warningMsg: null
    });
  };
 
  success = (msg, duration) => {
    message.destroy();
    message.success({
      content: msg,
      className: "custom-msg",
      duration: 4
    });
  };

  emailPassword=()=>{
    const { selection } = this.state;
		if (selection.length === 0 || selection.length > 1) {
      this.setState({ ...this.state, warningMsg: 'Please select the one record',selection: [],selectedObjs:[]  });
    } else {
      this.setState({ ...this.state, emailModal: true, warningMsg: null, isEmailLoading:true });
    }
    this.formref.current?.resetFields();
  }
  emailCancel=()=>{
    this.setState({
      ...this.state,
      modal: false,
      emailModal: false,
      selection: [],
      selectedObjs: [],
      check: false,
      errors: null,
      warningMsg: null
    });
    this.props.dispatch(setCurrentAction(null));
  };

  handleVerificationModelOpen=(val)=>{
   this.setState({...this.state, verificationsModalIsOpen: true,isEmailInput:val.email })
  }

buttonPhoneNoChangePassword=()=>{
  const { selection } = this.state;
  if (selection.length === 0 || selection.length > 1) {
    this.setState({ ...this.state, warningMsg: selection.length > 1?"Please select only one record": 'Please select the one record',selection: [] });
  } else {
    this.setState({ ...this.state, phoneNoChangeModal: true, warningMsg: null });
  }
  this.formref.current?.resetFields();
}
phoneNoChangeChangePassword=()=>{
    this.setState({
    ...this.state,
    modal: false,
    phoneNoChangeModal: false,
    selection: [],
    selectedObjs: [],
    check: false,
    errors: null,
    warningMsg: null,
    phoneErrorMsg:null
  });
  this.props.dispatch(setCurrentAction(null));
};

handleEmailPasswordChangeVerificationModelOpen=(val)=>{
  this.setState({...this.state, 
    verificationsModalIsOpen: true,
    isPhoneNoChange:val.phonecode + val.phoneNumber  })
}
  
 handleEmailFinalSave=async(val)=>{
  this.setState({...this.state,emailButtonLoader:true})
  let updateObj= {
    customerId:this.state.selectedObj?.id,
    exstingEmail: apiCalls.encryptValue(this.state.selectedObj?.email,this.props.userConfig?.sk),
    newEmail: apiCalls.encryptValue(val.email,this.props.userConfig?.sk)
  }
  const response = await changeEmail(updateObj)
  if(response.ok){
    this.emailCancel()
    this.setState({...this.state,emailButtonLoader:false})
    this.gridRef.current.refreshGrid();
  }
 }

 handlePhoneNoFinalSave=async(values)=>{
  this.setState({...this.state,phoneNoButtonLoader:true})  
  let updateObj={
    customerId:this.state.selectedObj?.id,
    exstingPhoneNo:apiCalls.encryptValue(this.state.selectedObj?.phoneNo,this.props.userConfig?.sk),
    newPhoneNo:apiCalls.encryptValue(values?.phonecode + values?.phoneNumber,this.props.userConfig?.sk)
    }
    const response = await changePhoneNo(updateObj)
    if(response.ok){
      this.success("Phone number updated successfully");
      this.setState({...this.state,phoneNoButtonLoader:false,phoneNoChangeModal:false,verificationsModalIsOpen:false,selection: [],selectedObjs:[],phoneErrorMsg:null })  
   this.gridRef.current.refreshGrid();
    }else{
      this.setState({...this.state,phoneErrorMsg:apiCalls.isErrorDispaly(response),phoneNoButtonLoader:false})
    }
 }


  handleOk = async () => {
    this.setState({ ...this.state, isdiabledLoading: true, warningMsg: null })
    if (!this.isLoading) {
      this.isLoading = true;
      let statusObj = this.state.obj;
      statusObj.id.push(this.state.selectedObj.id);
      statusObj.modifiedBy = this.props.userConfig?.userName;
      statusObj.status.push(this.state.selectedObj.status);
      statusObj.info = JSON.stringify(this.props.trackAuditLogData);
      statusObj.customerId = this.props.userConfig?.id;
      let response = await activeInactive(statusObj);
      if (response.ok) {
        this.props.dispatch(setCurrentAction(null));
        this.gridRef.current.refreshGrid();
        this.success(
          "Record " +
          (this.state.selectedObj.status === "Active"
            ? "deactivated"
            : "activated") +
          " successfully"
        );
        this.isLoading = false;
        this.setState({
          ...this.state,
          modal: false,
          selection: [],
          selectedObjs: [],
          check: false,
          isLoading: false,
          isdiabledLoading: false
        });
      } else {
        this.setState({
          ...this.state,
          isLoading: false,
          selection: [],
          selectedObjs: [],
          isCheck: false,
          isdiabledLoading: false,
          errors: response.status === 401 ? response.data.message : response.data
        });
        this.isLoading = false;
      }
    }

  };
  handlePasswordOk = async () => {
    this.setState({ ...this.state, isPassLoading: true, warningMsg: null })
    if (!this.isLoading) {
      this.isLoading = true;
      const response=await apiCalls.resetPassword(this.state.selectedObj?.id);
      if (response.ok) {
        this.props.dispatch(setCurrentAction(null));
        this.gridRef.current.refreshGrid();
        this.success("Reset password mail sent successfully");
        this.setState({
          ...this.state,
          passwordModal: false,
          selection: [],
          selectedObjs: [],
          check: false,
          isPassLoading: false
        });
        setTimeout(() => {
          this.isLoading = false;
        }, 2000);
     
      } else {
        this.setState({
          ...this.state,
          isLoading: false,
          selection: [],
          selectedObjs: [],
          isCheck: false,
          isPassLoading: false,
          errors: response.status === 401 ? response.data.message : response.data
        });
        this.isLoading = false;
      }
    }
  };
 
  handleCancel = () => {
    this.setState({
      ...this.state,
      modal: false,
      passwordModal: false,
      selection: [],
      selectedObjs: [],
      check: false,
      errors: null,
      warningMsg: null
    });
    this.props.dispatch(setCurrentAction(null));
  };
  showReqDocModal = () => {
    if (this.state.selection.length === 0) {
      this.setState({ ...this.state, warningMsg: 'Please select at least one document' });
      return;
    }
    this.setState({ docModal: true });
   
  };

  closeDocModal = () => {
    this.props.dispatch(setCurrentAction(null));
    this.setState({
      docModal: false,
      selection: [],
      check: false,
      documentRequest: this.createDocReq(),
      errorMsg: null,
      sendDisable: false
    });
  };
  handleRequestedDoc = (docs) => {
    let _docDetails = [];
    for (let obj of docs) {
      let detail = {
        id: obj.id,
        documentName: obj.name,
        documentId: this.state.documentRequest.id,
        remarks: ""
      };
      _docDetails.push(detail);
    }
    this.setState({
      ...this.state,
      documentRequest: { ...this.state.documentRequest, details: _docDetails }
    });
  };
  handleRequestedNoteChange = (value) => {
    this.setState({
      ...this.state,
      documentRequest: { ...this.state.documentRequest, note: value }
    });
  };
  createDocReq() {
    return {
      id: uuidv4(),
      transactionId: null,
      adminId: this.props?.userConfig?.id,
      date: new Date(),
      type: "Customer",
      customerId: null,
      note: "",
      remarks: "",
      details: []
    };
  }
  handleReqSend = async (values) => {
    let { documentRequest } = this.state;
    if (documentRequest.details.length === 0) {
      this.setState({ ...this.state, warningMsg: 'Please select at least one document' });
      return;
    }
    documentRequest.note = values.note;
    documentRequest.customerId = this.state.selection[0];
    documentRequest.info = JSON.stringify(this.props.trackAuditLogData);

    this.setState({ ...this.state, sendDisable: true });
    const response = await sendRequest(documentRequest);
    if (response.ok) {
      this.props.dispatch(setCurrentAction(null));
      this.closeDocModal();
      this.setState({ ...this.state, selection: [], check: false, sendDisable: false });
      this.success("Documents requested successfully")
    } else {
      this.setState({
        ...this.state,
        isLoading: false,
        selection: [],
        isCheck: false,
        sendDisable: false,
        errorMsg:
          response.status === 401 ? response.data.message : response.data
      });
    }

  };

  accountTypeSearch = async (value, prop) => {
    let response = await getAccountLu();
    if (response.ok) {
      this.setState({
        accounttypeData: response.data,vererrorMsg:null
      });
    }else{
      this.setState({...this.state,vererrorMsg: apiCalls.isErrorDispaly(response)})
    }
  };

  getNameLookup = async (emails, memberName) => {
    let response = await getNameLu(
      emails === null ? null : emails,
      memberName === null ? null : memberName
    );
    if (response.ok) {
      if (memberName) {
        this.setState({ ...this.state, customerNames: response.data,vererrorMsg:null });
      } else {
        this.setState({ ...this.state, searchObj: { customerType: "All", memberName: response.data[0].id }, customerNames: response.data,vererrorMsg:null});
      }
    }else {
      this.setState({ ...this.state, searchObj: {...this.state.searchObj, vererrorMsg:apiCalls.isErrorDispaly(response)} });
    }
  };

  handleChange = (value, prop) => {
    var val = "";
    let { accounttypeData, searchObj } = this.state;
    if (prop === "customerType") {
      let index = accounttypeData.findIndex(function (o) {
        return o.name === value;
      });
      val = accounttypeData[index].name;
      searchObj[prop] = prop === "customerType" ? val : value;
      this.setState({ ...this.state, searchObj });
    } else {
      const obj = this.state.customerNames?.find((item) => item.name === value);
      searchObj[prop] = prop === "memberName" && obj.id 
      this.setState({ ...this.state, searchObj });
    }
  };
  handleSearch = (e, type) => {
    let sval = e.target.value;
    if (sval !== null && sval.length > 2) {
      if (type === "memberName") {
        this.getNameLookup(null, sval);
      }
    }
  };

  handlebtnSearch = () => {
    let { searchObj } = this.state;
    this.setState({ ...this.state, searchObj, selection: [],selectedObjs: [], check: false, }, () => {
      this.gridRef.current.refreshGrid();
    });

  };
  createCaseView = (CaseData) => {
    let propsData = this.props
    CaseRediractions(CaseData, propsData)
  };

  createCase = () => {
    const { selection } = this.state;
		if (selection.length === 0 || selection.length > 1) {
      this.setState({ ...this.state, warningMsg: selection.length > 1?"Please select only one record": 'Please select the one record',selection: [] });
    }
    else {
      this.setState({ ...this.state, caseModal: true, check: false });
    }
  }
  editCustomers = () => {
    const { selection } = this.state;
		if (selection.length === 0 || selection.length > 1) {
      this.setState({ ...this.state, warningMsg:selection.length>1?"Please select only one record" : 'Please select the one record',selection: [] });
    } else {
      const obj = this.state.selectedObj;
      const val = obj.id;
      if (obj.status === "Inactive") {
        this.props.history.push("/customers/details/" + val + "/disabled");
        this.props.dispatch(
          setBreadcrumb({
            key: "/customers/details/" + val,
            val: obj.accountType !== "Business" ? (obj.firstName + " " + obj.lastName) : obj.businessName
          })
        );
      } else {
        this.props.history.push("/customers/details/" + val);
        this.props.dispatch(
          setBreadcrumb({
            key: "/customers/details/" + val,
            val: obj.accountType !== "Business" ? (obj.firstName + " " + obj.lastName) : obj.businessName
          })
        );
        this.setState({ ...this.state, warningMsg: null })
      }
    }

  };
  companyDetials = () => {
    const { selection } = this.state;
		if (selection.length === 0 || selection.length > 1) {
      this.setState({ ...this.state, warningMsg: selection.length > 1?"Please select only one record": 'Please select the one record',selection: [] });
    } else {
      const obj = this.state.selectedObj;
      const val = obj.id;
      if (obj.accountType === "Business" && obj.isKYB === "Yes") {
        this.props.history.push("/customers/kompany/" + val + "/view");
        this.props.dispatch(
          setBreadcrumb({
            key: "/customers/kompany/" + val,
            val: obj.firstName || obj.businessName
          })
        );
      } else {
        this.setState({ ...this.state, companyModal: true, warningMsg: null });
      }
    }

  };

  riskDetials = () => {
    const { selection } = this.state;
		if (selection.length === 0 || selection.length > 1) {
      this.setState({ ...this.state, warningMsg:selection.length > 1?"Please select only one record": 'Please select the one record',selection: [] });
    } else {
      this.setState({...this.state,riskModal:true})     
    } 
  };
  handleModalCancel=()=>{
    this.setState({...this.state,riskModal:false,selection: [],selectedObjs: [],check:false})
    this.gridRef.current.refreshGrid();

  }
  cardDetials = () => {
    this.props.history.push("/customers/cards");
   
  };
  topupDetails = () => {
    this.props.history.push("/customers/topups");
  };
  closeComModal = () => {
    this.setState({ companyModal: false, selection: [],selectedObjs: [], check: false, warningMsg: null, lockModal: false });
  };
  closeModal = () => {
    this.setState({ informationModal: false, selection: [],selectedObjs: [], check: false, vererrorMsg: null, verWarningMsg: null, warningMsg: null });
  };
  batchEditCloseModal = () => {
    this.setState({ companyModal: false, selection: [],selectedObjs: [], check: false, warningMsg: null, batchEditModal: false });
  }

  editCommission = () => {
    const { selection } = this.state;
		if (selection.length === 0) {
      this.setState({ ...this.state, warningMsg: 'Please select the one record',selectedObj:null,selection:[] });
      window.scrollTo(0, 0)
    } else {
      const obj = this.state.selectedObj;
      const val = obj.id;
      if(selection.length === 1) {
        this.props.history.push("/customers/details/" + val + "/fees");
        this.props.dispatch(
          setBreadcrumb({
            key: "/customers/details/" + val,
            val: obj.accountType != "Business" ?  obj.firstName + "  " + obj.lastName : obj.businessName
          })
        );
      }
       else {
      this.setState({ ...this.state, warningMsg: null, batchEditModal: true })
    }
  }
}

  onActionClick = (key) => {
    const actions = {
      disable: "statusUpdate",
      recorder: "statusUpdate",
      "Reset Password": "resetPassword",
      "Doc Request": "showReqDocModal",
      edit: "editCustomers",
      "Edit Commissions": "editCommission",
      "Create Case": "createCase",
      Verification: "Information",
      "Lock/Unlock": "lockUnlock",
      Kompany: "companyDetials",
      "Risk Screen": "riskDetials",
      "State Change": "handleStateChange",
      "Edit Suissebase Credit":"editSuissebaseCredit",
      "Change Phone Number" : "buttonPhoneNoChangePassword",
      "Change Email" : "emailPassword"
      
    };
    this[actions[key]]();
  };
  editSuissebaseCredit=()=>{
    const { selection } = this.state;
		if (selection.length<=1) {
      this.setState({ ...this.state, warningMsg:'Please select more than one record',selection: [] });
      window.scrollTo(0, 0)
    }
    else {
      this.setState({ ...this.state, suisseBaseCreditvisible: true })
    }
  }
    handleModalCloseCreid=()=>{
      this.setState({ ...this.state, isModalOpen: false,selection: [],selectedObjs: [], check: false, })
  }
  isUrlValid(userInput) {
    var res = userInput.match(/(http(s):\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
    if (res == null)
      return false;
    else if (userInput.startsWith("http://") || userInput.startsWith("https://") || userInput.startsWith("www.")) {
      return true;
    }
    else {
      return false
    }

  }
  antIcon = () => {
    (
      <LoadingOutlined
        style={{ fontSize: 18, color: "#fff", marginRight: "16px" }}
        spin
      />
    );
  }
  handleStateChange = () => {
    const { selection } = this.state;
		if (selection.length === 0 || selection.length > 1) {
      this.setState({ ...this.state, warningMsg: selection.length > 1?"Please select only one record": 'Please select the one record',selection: [],selectedObjs:[] });
    } else {
      this.setState({ ...this.state, showStateChange: true })
    }
  }
  handleModalCloseCreidit = ()=>{
    this.setState({...this.state, suisseBaseCreditvisible:false, check: false, selection: [], selectedObjs:[],selectedObj: null})
    this.gridRef.current.refreshGrid();
  }
  getWarningAlert=(warningMsgs)=>(
    warningMsgs && (
      <div style={{ width: '100%' }}>
        <Alert
          className="w-100 mb-16"
          type="warning"
          description={warningMsgs}
          showIcon
        />
      </div>
    )
  )
  getErrorMsg=(errorMsgs)=>(
    errorMsgs !== undefined && errorMsgs !== null && (
      <Alert type="error" className="mb-16" showIcon description={errorMsgs} />
    )
  )

  renderModalTitle=()=>(
    this.state.selectedObj?.status === "Active" ? "Confirm Deactivate?" : "Confirm Activate?"
  )

  renderEmailField = () => {
    return <Form.Item
      name="email"
      label="Email Verification"
      className="input-label"
      rules={[
        {
          validator: (_, Val) => {
            if (!Val) {
              return Promise.reject("Is required");
            }
            if (Val.length < 5 || Val.length > 50) {
              return Promise.reject("Invalid Email");
            } else if (!(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,100}$/.test(Val))) {
              return Promise.reject("Invalid Email");
            } else {
              return validateContentRule(_, Val)
            }
          }
        }
      ]}
    >
      <Input placeholder="enter your new email" />
    </Form.Item>
  }
  renderPhoneNumberField = () => {
    return <Form.Item
      name="phoneNumber"
      className="input-label"
      type="number"
      rules={[
        { required: true, message: "Is required" },
        {
          validator(_, value) {
            if (!value) {
              return Promise.resolve();
            } else if (Number(value) && !value.includes(".")) {
              return Promise.resolve();
            } else {
              return Promise.reject("Only numbers allowed");
            }
          },
        },
      ]}>
      <Input placeholder="Phone Number" className="cust-input" maxLength={12} />
    </Form.Item>
  }
  render() {
    const { gridUrl, isLoading, isVerLoading, errors, errorMsg, accounttypeData, searchObj, disableObj, phoneCheck, emailCheck, kycCheck, caseModal, warningMsg, vererrorMsg, verWarningMsg, isPassLoading, isLockLoading,emailButtonLoader,phoneNoButtonLoader } = this.state;
    const uniqueOptions =this.state.FilteredCodeCountries.reduce((acc, option) => {
      const existingOption = acc.find(o => o.value === option.value);
      if (!existingOption) {
        acc.push(option);
      }
      return acc;
    }, [])
    const options = accounttypeData.map((d) => (
      <Option key={d.code} value={d.name}>
        {d.name}
      </Option>
    ));
    return (
      
      <>
      <div ref={this.useDivRef}>
        <div>
          {isLoading && <Loader />}
          {this.getWarningAlert(warningMsg)}
          <Form
            initialValues={this.state.accounttypeData}
            className="ant-advanced-search-form form form-bg search-bg pt-8"
            autoComplete="off"
          >
            <Row style={{ alignItems: "flex-end" }}>
              <Col sm={24} xs={24} md={7} className="px-8">
                <Form.Item
                  name="customerType"
                  className="input-label mb-0"
                  label="Account Type"
                >
                  <Select
                    defaultValue="All"
                    className="cust-input w-100"
                    showSearch
                    onChange={(e) => this.handleChange(e, "customerType")}
                    placeholder="Select Account Type"
                  >
                    {options}
                  </Select>
                </Form.Item>
              </Col>
              <Col sm={24} xs={24} md={7} className="px-8">
                <Form.Item
                  name="names"
                  className="input-label mb-0"
                  label="Name"
                >
                  <Select
                    className="cust-input w-80"
                    showSearch
                    defaultValue="All"
                    onKeyUp={(e) => this.handleSearch(e, "memberName")}
                    onChange={(e) => this.handleChange(e, "memberName")}
                    placeholder="Select Name"
                  >
                    {this.state.customerNames?.map((item, idx) => (
                      <Option key={idx} value={item.name}>
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col sm={24} md={3} lg={3} xl={3} xxl={3} className="px-8 text-right">             
             <Button
               type="primary"
               className="primary-btn px-24 search-btn mt-20"
               htmlType="submit"
               onClick={this.handlebtnSearch}
             >Search
             </Button>            
           </Col>
            </Row>
          </Form>
        </div>

          <List
            showActionBar={true}
            onActionClick={(key) => this.onActionClick(key)}
            pKey={"Customers"}
            additionalParams={searchObj}
            url={gridUrl}
            ref={this.gridRef}
            columns={this.gridColumns}
            showExcelExport={true}
            pSize={50}
            className="customer-table-header table-checkalign"
          />
        </div>
        <Modal
         title={"Change Email"}
         visible={this.state.emailModal}
         closeIcon={
           <Tooltip title="Close">
             <span className="icon md x c-pointer" 
             onClick={this.emailCancel} 
             />
           </Tooltip>
         }
         footer={null}                   
         >
          <Form
          	ref={this.formref}
           onFinish={this.handleVerificationModelOpen}>
            {this.renderEmailField()}
            <Form.Item>
            <div className="text-right">
            <Button
              type="primary"
              className="primary-btn"
              htmlType="submit"
              loading={emailButtonLoader}
            >
             continue
            </Button> 
            </div>
            </Form.Item>
          </Form>
        

        </Modal>
        {this.state.verificationsModalIsOpen && <VerificationsModal
        verificationsComplete={() => {this.setState({...this.state, verificationsModalIsOpen: true})} }
         visible={this.state.verificationsModalIsOpen}
         closeModal={()=> {
          this.setState({
            ...this.state,
            emailModal: false,
            verificationsModalIsOpen: false,
            selection: [],
            selectedObjs: [],
            check: false,
            error: null,
            warningMsg: null
          });
          this.props.dispatch(setCurrentAction(null));
         }}
         onConfirmButton={this.handlePhoneNoFinalSave}
         loadingBtn={phoneNoButtonLoader}
      />}
          <Modal
         title={"Change Phone Number"}
         visible={this.state.phoneNoChangeModal}
         closeIcon={
           <Tooltip title="Close">
             <span className="icon md x c-pointer" 
             onClick={this.phoneNoChangeChangePassword} 
             />
           </Tooltip>
         }
         footer={null}                 
        >
           {this.getErrorMsg(this.state.phoneErrorMsg)}
          <Form
          	ref={this.formref}
           onFinish={this.handlePhoneNoFinalSave}
           className="ant-advanced-search-form custom-overflow"
           name="advanced_search"
           >
            <Row gutter={24} className="mb-24 pb-24 border-bottom">            
              <Col xs={24} sm={24} md={12} lg={24} xxl={24} className="phone-vth-code">
                    <Form.Item
                     name="phonecode"
                     className="input-label mb-0"
                      label="Phone Number"
                        rules={[
                            {
                                required: true,
                                message: 'Is required',
                            },
                        ]}>
                  <Select
                    className="cust-input w-100 bgwhite"
                    maxLength={100}
                    placeholder="Select"
                    optionFilterProp="children"
                    showSearch
                    filterOption={(input, option) =>
                      (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    options={uniqueOptions}                  
                  />
                    </Form.Item>
                    {this.renderPhoneNumberField()}
                </Col>
            </Row>
            <Form.Item className="mb-0">
            <div className="text-right">
            <Button
                type="primary"
                className="primary-btn cancel-btn"
                onClick={this.phoneNoChangeChangePassword}               >
                Cancel
              </Button>
            <Button
              type="primary"
              className="primary-btn ml-8"
              htmlType="submit"
            loading={phoneNoButtonLoader}
            >
             Confirm
            </Button> 
            </div>
            </Form.Item>
            
          </Form>
         
        </Modal>
        {this.state.phoneNoChangeModalIsOpen && <VerificationsModal
                 verificationsComplete={()=>{this.setState({...this.state,phoneNoChangeModalIsOpen: true,})}}
         visible={this.state.phoneNoChangeModalIsOpen}
         closeModal={()=> {
          this.setState({
            ...this.state,
            phoneNoChangeModal: false,
            phoneNoChangeModalIsOpen: false,
            selection: [],
            selectedObjs: [],
            check: false,
            error: null,
            warningMsg: null
          });
          this.props.dispatch(setCurrentAction(null));
         }}
         onConfirmButton={this.handlePhoneNoFinalSave}
         loadingBtn={phoneNoButtonLoader}
      />}
        <Modal
          title={this.renderModalTitle()}
          visible={this.state.modal}
          closeIcon={
            <Tooltip title="Close">
              <span className="icon md x c-pointer" onClick={this.handleCancel} />
            </Tooltip>
          }
          footer={
            <>
              <Button
                type="primary"
                className="primary-btn cancel-btn"
                onClick={this.handleCancel}
              >
                No
              </Button>
              <Button
                type="primary"
                className="primary-btn"
                onClick={this.handleOk}
                loading={this.state.isdiabledLoading}
              >
                Yes
              </Button>
            </>
          }
        >
          {this.getErrorMsg(errors)}
          <p className="fs-16 mb-0">
            Do you really want to{" "}
            {this.state.selectedObj?.status === "Active" ? "deactivate?" : "activate?"}
          </p>
        </Modal>
        <Modal
          title={"Confirm Reset Password?"}
          visible={this.state.passwordModal}
          closeIcon={
            <Tooltip title="Close">
              <span className="icon md x c-pointer" onClick={this.handleCancel} />
            </Tooltip>
          }
          footer={
            <>
              <Button
                type="primary"
                className="primary-btn cancel-btn"
                onClick={this.handleCancel}
              >
                No
              </Button>
              <Button
                type="primary"
                className="primary-btn"
                onClick={this.handlePasswordOk}
                loading={isPassLoading}
              >
                Yes
              </Button>
            </>
          }
        >
          {this.getErrorMsg(errors)}
          <p className="fs-16 mb-0">
            Do you really want to reset{" "}
            <span className="text-red fw-500">
              {this.state.selectedObj?.userName}
            </span>{" "}
            password?
          </p>
        </Modal>
        <Modal
          className="documentmodal-width"
          title="Documents Requests"
          visible={this.state.docModal}
          destroyOnClose={true}
          width={1000}
          closeIcon={
            <Tooltip title="Close">
              <span className="icon md x c-pointer" onClick={this.closeDocModal} />
            </Tooltip>
          }
          footer={null}
        >
          {this.getErrorMsg(errorMsg)}
          <Form
            name={this.docReqForm}
            initialValues={this.state.documentRequest}
            onFinish={(values) => this.handleReqSend(values)}
          >
            <div className="mb-24">
              <Form.Item
                rules={[
                  { required: true, message: "Is required" },
                  { whitespace: true, message: "Is required" },
                  { validator: validateContentRule }
                ]}
                name="note"
                label="Title"
                className="input-label"
              >
                <Input
                  maxLength="150"
                  id="note"
                  className="cust-input"
                  placeholder="Enter Title"
                />
              </Form.Item>
            </div>
            <DocumentsList
              onDocSelect={(docs) => {
                this.handleRequestedDoc(docs);
              }}
            />
            <Form.Item className="text-right mb-0 docmodal-footer">
              <Button
                type="primary"
                onClick={this.closeDocModal}
                className="primary-btn cancel-btn mr-16"
              >
                Cancel
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                className="primary-btn"
                loading={isLoading}
              >
                Send
              </Button>
            </Form.Item>
          </Form>
        </Modal>
        <Modal
          title="Customer Verifications"
          visible={this.state.informationModal}
          closable={false}
          footer={
            <>
              <Button
                type="primary"
                className="primary-btn cancel-btn"
                onClick={this.closeModal}
              >
                Cancel
              </Button>
              {(disableObj.isEmailverified === true && disableObj.isPhoneverified === true && disableObj.isKyc === true) ||
                <Button
                  type="primary"
                  className="primary-btn"
                  onClick={this.saveInformation}
                  loading={isVerLoading}
                >
                  Save
                </Button>}
            </>
          }
        >
          <p className="fs-16 mb-0 ">
          {this.getWarningAlert(verWarningMsg)}
           {this.getErrorMsg(vererrorMsg)}
         
            Please select the checkbox to approve customer verifications
          </p >
          <br></br>
          {this.state.stateLoading && <Loader />}
          <Form>
            <div className="d-flex profile-block text-center">
              <div className="d-flex align-center mr-8">
                <label className="text-center custom-checkbox mr-8">
                  <input
                    name="check"
                    type="checkbox"
                    checked={emailCheck}
                    disabled={disableObj.isEmailverified}
                    onChange={(e) => this.handleCheckChange(e, "emailCheck")}
                  />
                  <span></span>
                </label>
                <span>Email</span>
              </div>
              <div className="d-flex align-center mr-8">
                <label className="text-center custom-checkbox mr-8">
                  <input
                    name="check"
                    type="checkbox"
                    checked={phoneCheck}
                    disabled={disableObj.isPhoneverified}
                    onChange={(e) => this.handleCheckChange(e, "phoneCheck")}
                  />
                  <span></span>
                </label>
                <span>Phone</span>
              </div>
              <br />
              <div className="d-flex align-center">
                <label className="text-center custom-checkbox mr-8">
                  <input
                    name="check"
                    type="checkbox"
                    checked={kycCheck}
                    disabled={disableObj.isKyc}
                    onChange={(e) => this.handleCheckChange(e, "kycCheck")}
                  />
                  <span></span>
                </label>
                <span>KYC/KYB</span>
              </div>
            </div>
          </Form>
        </Modal >
        <Modal
          title="Information"
          visible={this.state.companyModal}
          closeIcon={
            <Tooltip title="Close">
              <span className="icon md x c-pointer" onClick={this.closeComModal} />
            </Tooltip>
          }
          footer={
            <>
              <Button
                type="primary"
                className="primary-btn cancel-btn"
                onClick={this.closeComModal}
              >
                Cancel
              </Button>
            </>
          }
        >
          <p className="fs-16 mb-0 ">
            KYB completed users only have provision to see kompany details.
          </p>
        </Modal>
        <Modal
          title="Create Case"
          visible={caseModal}
          width={1000}
          style={{ top: 16 }}
          closeIcon={
            <Tooltip title="Close">
              <span className="icon md x c-pointer" onClick={this.hideCaseModal} />
            </Tooltip>
          }
          footer={null}
          destroyOnClose
        >
          <CreateCase FromScreen='Customer' ScreenId={this.state.selectedObj?.id} CreateCaseId={this.state.selectedCaseId} ScreenName="customers" closeModal={this.hideCaseModal} />
        </Modal>
        <Modal
          title={this.state.selectedObj?.isLocked ? "Confirm Unlock?" : "Confirm Lock?"}
          visible={this.state.lockModal}
          closeIcon={
            <Tooltip title="Close">
              <span className="icon md x c-pointer" onClick={this.closeComModal} />
            </Tooltip>
          }
          footer={
            <>
              <Button
                type="primary"
                className="primary-btn cancel-btn"
                onClick={this.closeComModal}
              >
                No
              </Button>
              <Button
                type="primary"
                className="primary-btn"
                onClick={this.saveLock}
                loading={isLockLoading}
              >
                Yes
              </Button>

            </>
          }
        >
          <p className="fs-16 mb-0 ">
          {this.getErrorMsg(vererrorMsg)}
           
          </p >
          <p className="fs-16 mb-0">
            Do you really want to{" "}
            {this.state.selectedObj?.isLocked ? "unlock?" : "lock?"}
          </p>
        </Modal>
        <Modal
          title={
            "Batch Edit"}
          visible={this.state.batchEditModal}
          width={1350}
          style={{ top: 16 }}
          closeIcon={
            <Tooltip title="Close">
              <span className="icon md x c-pointer" onClick={this.batchEditCloseModal} />
            </Tooltip>
          }
          footer={null}
        >
         <div>
         <CustomerBatchFee selectionIds={this.state?.selection} selectionObjects={this.state?.selectedObjs} isBatchEdit={true} 
             onBatchEditClose={() => this.setState({ ...this.state, batchEditModal: false, check: false, selection: [],selectedObjs: [], selectedObj: null },()=>this.gridRef.current.refreshGrid())}
          ></CustomerBatchFee>
         </div>
        </Modal>

        <CustomerStateChange
          visible={this.state.showStateChange}
          onClose={() => this.setState({ ...this.state, showStateChange: false, check: false, selection: [],selectedObjs: [], selectedObj: null },()=>this.gridRef.current.refreshGrid())}
          selectedCustomer={this.state.selectedObj}
        />
         {this.state.riskModal && (
          <RiskDetialsPage riskModal={this.state.riskModal} pop_up_cancel={this.handleModalCancel} gridID={this.state.selectedObj.id}/>
        )}
{this.state.suisseBaseCreditvisible&&<SuisseBaseCredits showModal={this.state.suisseBaseCreditvisible} handleModalCloseCreidit={()=>this.handleModalCloseCreidit()}  isShowLogs={false} customerId={this.state.selection} availableBlc={this.state.selectedObj.credit}/>}
      </>
    );
  }
}
const connectStateToProps = ({ oidc, userConfig }) => {
  return {
    oidc, userConfig: userConfig?.userProfileInfo, trackAuditLogData: userConfig?.trackAuditLogData
  };
};
const connectDispatchToProps = (dispatch) => {
  return {
    dispatch
  };
};
export default connect(connectStateToProps, connectDispatchToProps)(Customers);