import React, { useEffect, useState } from "react";
import { Row, Col, Typography, Button, Alert } from "antd";
import Loader from "../loader.component";
import { publishShowActions } from "../grid.component/subscribir";
import { getPayeeData } from "./api";
import { bytesToSize } from "../../utils/service";
import { connect } from 'react-redux';
import { setCurrentAction } from '../../reducers/actionsReducer';
import { redirectToCaseView } from "../../utils/caseRediractions";
import { Link } from "react-router-dom";
import apiCalls from "../../api/apiCalls";
import DocumentPreview from "../shared/documentPreview";
import Info from "../info/Info";
import DocumentsUploade from '../../utils/documents';
import { PlusCircleOutlined } from '@ant-design/icons';
import Notes from "../../utils/notes";
import Moment from 'react-moment';
import config from "../../config";

const { Title, Text } = Typography;
const EllipsisMiddle = ({ suffixCount, children }) => {
	const start = children?.slice(0, children.length - suffixCount).trim();
	const suffix = children?.slice(-suffixCount).trim();
	return (
		<Text
			className="mb-0 fs-14 docnames c-pointer d-block"
			style={{ maxWidth: "100% !important" }}
			ellipsis={{ suffix }}>
			{start}
		</Text>
	);
};
const AddressFiatView = (props) => {
	const [loading, setLoading] = useState(false);
	const [fiatData, setFiatData] = useState({});
	const [error, setError] = useState(null);
	const [docPreviewModal, setDocPreviewModal] = useState(false)
	const [docPreviewDetails, setDocPreviewDetails] = useState(null)
	const [isModalVissible, setIsVissible] = useState(false);
	const [docViewObj, setIsDocViewObj] = useState([]);

	useEffect(() => {
		publishShowActions(false);
		getLoadData();
	}, []);//eslint-disable-line react-hooks/exhaustive-deps
	const getLoadData = async () => {
		setLoading(true);
		let response = await getPayeeData(props.match.params.payeeId, props.match.params.id);
		if (response.ok) {
			setError(null)
			setFiatData(response.data);
			setIsDocViewObj(response.data.adminDocuments)
			let fileDetails = response.data.docRepoistryLists;
			if (fileDetails) {
				if (fileDetails.length !== 0) {
					fileDetails.forEach((item) => {
						let obj = {};
						obj.id = item.id;
						obj.name = item.documentName;
						obj.size = item.remarks;
						obj.response = [item.path];
					});
				}
			}
		} else {
			setError(apiCalls.isErrorDispaly(response));
		}
		setLoading(false);
	};

	const backToAddressBook = () => {
		props.history.push("/addressbook");
	};
	const redirectCaseView = (caseData) => {
		redirectToCaseView(caseData, props)
	};
	const docPreviewOpen = (data) => {
		setDocPreviewModal(true)
		setDocPreviewDetails({ id: data.id, fileName: data.fileName })
	}
	const docPreviewClose = () => {
		setDocPreviewModal(false)
		setDocPreviewDetails(null)
	}
	const docOpen = () => {
		setIsVissible(true);
	}
	const CloseModal = () => {
		setIsVissible(false);
	}
	const updateAttachemnts = (attachemnts) => {
		setIsDocViewObj(attachemnts)
	}

	return (
		<>
			{loading && <Loader />}
			{error !== undefined && error !== null && <Alert type="error" className="mb-16" showIcon message={error} />}
			<Title className="page-title"> RECIPIENT BANK DETAILS</Title>
			{fiatData && (
				<>
					<Row gutter={8}>
						<Col xl={24} xxl={24} className="bank-view">
							{(fiatData?.walletCode != "CHF" && fiatData?.transferType !== "internationalIBAN" && ((fiatData?.walletCode == "EUR" || fiatData?.walletCode == 'USD' || fiatData?.walletCode === "GBP") && (fiatData?.transferType === "swifttransfer" || fiatData?.transferType === "domestic" || fiatData?.transferType == "international"))) &&
								<Row className="kpi-List" style={{ paddinTop: '10' }}>
									<Col xs={24} sm={24} md={12} lg={8} xxl={8}>

										<div>
											<label className="kpi-label">Created Date</label>
											<div className=" kpi-val">
												{fiatData?.createddate && (
													<Moment format={config?.dateFormates?.dateTimeFormate}>
														{apiCalls.convertUTCToLocalTime(fiatData?.createddate)}
													</Moment>
												)}
												{!fiatData?.createddate && ("--")}
											</div>
										</div>
									</Col>

									<Col xs={24} sm={24} md={12} lg={8} xxl={8}>

										<div>
											<label className="kpi-label">Whitelist Name</label>
											<div className=" kpi-val">{fiatData?.whiteListName || "-"}</div>
										</div>
									</Col>

									<Col xs={24} sm={24} md={12} lg={8} xxl={8}>
										<div>
											<label className="kpi-label">Address Type</label>
											<div className=" kpi-val">
												{(fiatData?.addressType === " ") || fiatData?.addressType === null ?
													"-" : ((fiatData?.addressType?.toLowerCase() === "myself") && ("My Self")) ||
													((fiatData?.addressType?.toLowerCase() === "individuals") && ("Individuals")) ||
													((fiatData?.addressType?.toLowerCase() === "ownbusiness") && ("My Company")) ||
													((fiatData?.addressType?.toLowerCase() === "Business") && ("Other Business")) ||
													((fiatData?.addressType?.toLowerCase() === "otherbusiness") && ("Other Business"))}
											</div>
										</div>
									</Col>
									<Col xs={24} sm={24} md={12} lg={8} xxl={8}>
										<div>
											<label className="kpi-label">Currency</label>
											<div className=" kpi-val">{fiatData?.walletCode || "-"}</div>
										</div>
									</Col>

									<Col xs={24} sm={24} md={12} lg={8} xxl={8}>
										<div>
											<label className="kpi-label">Bank Account Number/IBAN</label>
											<div className=" kpi-val">
												{fiatData?.accountNumber || "-"}
											</div>
										</div>
									</Col>
									<Col xs={24} sm={24} md={12} lg={8} xxl={8}>
										<div>
											<label className="kpi-label">BIC/SWIFT/ABA Routing/Uk Sort Code</label>
											<div className=" kpi-val">{fiatData?.swiftRouteBICNumber || fiatData?.ukShortCode || "-"}</div>
										</div>
									</Col>
									<Col xs={24} sm={24} md={12} lg={8} xxl={8}>
										<div>
											<label className="kpi-label">Bank Name</label>
											<div className="kpi-val">{fiatData?.bankName || "-"}</div>
										</div>
									</Col>
									<Col xs={24} sm={24} md={12} lg={8} xxl={8}>
										<div>
											<label className="kpi-label">Bank Country</label>
											<div className="kpi-val">{fiatData?.country || "-"}</div>
										</div>
									</Col>
									<Col xs={24} sm={24} md={12} lg={8} xxl={8}>
										<div>
											<label className="kpi-label">Bank Address</label>
											<div className="kpi-val">{fiatData?.bankaddress || "-"}</div>
										</div>
									</Col>

								</Row>}
							{((fiatData?.walletCode === "CHF" || fiatData?.transferType === "internationalIBAN") || !(fiatData?.transferType == "swifttransfer" || fiatData?.transferType == 'domestic' || fiatData?.transferType == "international")) && <Row className="kpi-List" style={{ paddinTop: '10' }}>
								<Col xs={24} sm={24} md={12} lg={8} xxl={8}>

									<div>
										<label className="kpi-label">Created Date</label>
										<div className=" kpi-val">
											<Moment format={config?.dateFormates?.dateTimeFormate}>
												{fiatData?.createddate
													? apiCalls.convertUTCToLocalTime(
														fiatData?.createddate
													)
													: fiatData?.createddate}
											</Moment>
										</div>
									</div>
								</Col>
								<Col xs={24} sm={24} md={12} lg={8} xxl={8}>
									<div>
										<label className="kpi-label">Whitelist Name</label>
										<div className=" kpi-val">{fiatData?.whiteListName || "-"}</div>
									</div>
								</Col>
								<Col xs={24} sm={24} md={12} lg={8} xxl={8}>
									<div>
										<label className="kpi-label">Address Type</label>
										<div className="kpi-val">
											{(fiatData?.addressType === " ") ||
												fiatData?.addressType === null
												? "-"
												: ((fiatData?.addressType?.toLowerCase() === "myself") && ("My Self")) ||
												((fiatData?.addressType?.toLowerCase() === "individuals") && ("Individuals")) ||
												((fiatData?.addressType?.toLowerCase() === "ownbusiness") && ("My Company")) ||
												((fiatData?.addressType?.toLowerCase() === "Business") && ("Other Business")) ||
												((fiatData?.addressType?.toLowerCase() === "otherbusiness") && ("Other Business"))}
										</div>
									</div>
								</Col>
								<Col xs={24} sm={24} md={12} lg={8} xxl={8}>
									<div>
										<label className="kpi-label">Currency</label>
										<div className="kpi-val">{fiatData?.walletCode || "-"}</div>
									</div>
								</Col>
								<Col xs={24} sm={24} md={12} lg={8} xxl={8}>
									<div>
										<label className="kpi-label">IBAN</label>
										<div className="kpi-val">{fiatData?.iban || fiatData?.accountNumber || "-"}</div>
									</div>
								</Col>
								<Col xs={24} sm={24} md={12} lg={8} xxl={8}>
									<div>
										<label className="kpi-label">Bank Name</label>
										<div className="kpi-val">{fiatData?.bankName || "-"}</div>
									</div>
								</Col>
								<Col xs={24} sm={24} md={12} lg={8} xxl={8}>
									<div>
										<label className="kpi-label">BIC</label>
										<div className="kpi-val">{fiatData?.bic || "-"}</div>
									</div>
								</Col>
								<Col xs={24} sm={24} md={12} lg={8} xxl={8}>
									<div>
										<label className="kpi-label">Branch</label>
										<div className="kpi-val">{fiatData?.bankBranch || "-"}</div>
									</div>
								</Col>
								<Col xs={24} sm={24} md={12} lg={8} xxl={8}>
									<div>
										<label className="kpi-label">Country</label>
										<div className="kpi-val">{fiatData?.country || "-"}</div>
									</div>
								</Col>
								<Col xs={24} sm={24} md={12} lg={8} xxl={8}>
									<div>
										<label className="kpi-label">State</label>
										<div className="kpi-val">{fiatData?.state || "-"}</div>
									</div>
								</Col>
								<Col xs={24} sm={24} md={12} lg={8} xxl={8}>
									<div>
										<label className="kpi-label">City</label>
										<div className="kpi-val">{fiatData?.city || "-"}</div>
									</div>
								</Col>
								<Col xs={24} sm={24} md={12} lg={8} xxl={8}>
									<div>
										<label className="kpi-label">Zip</label>
										<div className="kpi-val">{fiatData?.postCode || "-"}</div>
									</div>
								</Col>

							</Row>}
							<br />
							<Title className="page-title">RECIPIENT DETAILS</Title>
							<Row className="kpi-List">
								<Col xs={24} sm={24} md={12} lg={8} xxl={8}>
									<div>
										<label className="kpi-label">Recipient Full Name</label>
										<div className="kpi-val">{fiatData?.name || "-"}</div>
									</div>
								</Col>
								{(fiatData?.addressType === "individuals" || fiatData?.addressType === "otherbusiness") && (
									<Col xs={24} sm={24} md={12} lg={8} xxl={8}>
										<div>
											<label className="kpi-label">Relationship To Beneficiary</label>
											<div className="kpi-val">{fiatData?.beneficiaryRelation || "-"} {"  "}  {fiatData?.beneficiaryRelation == "Others" && `(${fiatData?.others})`}</div>
										</div>
									</Col>
								)}
								<Col xs={24} sm={24} md={12} lg={8} xxl={8}>
									<div>
										<label className="kpi-label">Recipient Address</label>
										<div className="kpi-val">{fiatData?.recipientAddress || "-"}</div>
									</div>
								</Col>
								<Col xs={24} sm={24} md={12} lg={8} xxl={8}>
									<div>
										<label className="kpi-label">Whitelisting Status</label>
										<div className="kpi-val">{fiatData?.addressState || "-"}</div>
									</div>
								</Col>
								{fiatData?.addressState === "Rejected" && (<Col xs={24} sm={24} md={12} lg={8} xxl={8}>
									<div>
										<label className="kpi-label">Reason For Rejection</label>
										<div className="kpi-val">{fiatData?.rejectReason || "-"}</div>
									</div>
								</Col>)}
								{fiatData?.caseIds !== null && (
									<Col xs={24} sm={24} md={24} lg={24} xl={8} xxl={6}>
										<div>
											<label className="kpi-label d-block">Case Number</label>
											<div
												className="fw-600 fs-14">
												{fiatData?.caseIds?.map(item => <Link className="c-pointer" onClick={() => redirectCaseView(item)}>{item.caseNumber}<br /></Link>)}{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}

											</div>
										</div>
									</Col>
								)}
							</Row>
							<Row>
								{fiatData?.docRepoistryLists?.map((file) => (
									<Col xs={24} sm={24} md={12} lg={8} xxl={8} className="">
										<div
											className="docfile mr-8 mt-24 d-flex align-center"
											key={file.id}>
											<span
												className={`icon xl ${(file.fileName?.slice(-3) === "zip" && "file") ||
													(file.fileName?.slice(-3) !== "zip" && "") ||
													((file.fileName?.slice(-3) === "pdf" ||
														file.fileName?.slice(-3) === "PDF") &&
														"file") ||
													(file.fileName?.slice(-3) !== "pdf" &&
														file.fileName?.slice(-3) !== "PDF" &&
														"image")
													} mr-16`}
											/>
											<div
												className="docdetails c-pointer"
												onClick={() => docPreviewOpen(file)}
											>
												{file.name !== null ? (
													<EllipsisMiddle suffixCount={6}>
														{file.fileName}
													</EllipsisMiddle>
												) : (
													<EllipsisMiddle suffixCount={6}>Name</EllipsisMiddle>
												)}
												<span className="fs-12 text-secondary">
													{bytesToSize(file.fileSize)}
												</span>
											</div>
										</div>
									</Col>
								))}
							</Row>
						</Col>
					</Row>
					<p className="mt-16"><b> Admin Upload</b> <PlusCircleOutlined onClick={() => docOpen()} /></p>
					<Row gutter={24} className="mb-24 pb-24 border-bottom">
						<>
							<Col xs={24} sm={24} md={24} xl={24} xxl={24}>
								{<DocumentsUploade
									pop_up_cancel={() => CloseModal()}
									docID={fiatData?.id}
									previewModal={isModalVissible}
									attachmentUpdate={(attachemnts) =>
										updateAttachemnts(attachemnts)
									}
									docViewObj={docViewObj}
									screenTitle={"Address Book Fiat"}
									ModifiedBy={props.userConfig.userName}
								/>}
							</Col>
						</>
					</Row>
					<Notes screenId={props.match.params.payeeId} screenName={"AddressbookFiat"} createdNote={fiatData.notes}></Notes>
					<Info Id={fiatData.id} Status={fiatData?.status} Createdby={fiatData?.userCreated} CreatedDate={fiatData?.createddate} ModifiedDate={fiatData?.modifiedDate} Modifiedby={fiatData?.modifiedBy} isShowInfo={false} screenName={"AddressBook Fiat"} />
					{docPreviewModal &&
						<DocumentPreview
							previewModal={docPreviewModal}
							handleCancle={docPreviewClose}
							upLoadResponse={docPreviewDetails}
						/>
					}
				</>
			)}
			<div className="text-right mt-24">
				<Button
					type="primary"
					className="primary-btn cancel-btn"
					style={{ margin: "0 8px" }}
					onClick={backToAddressBook}>
					Cancel
				</Button>
			</div>
		</>

	);
};
const connectStateToProps = ({ userConfig }) => {
	return { userConfig: userConfig.userProfileInfo }
}
const connectDispatchToProps = dispatch => {
	return {
		setAction: (val) => {
			dispatch(setCurrentAction(val))
		},
		dispatch
	}
}
export default connect(connectStateToProps, connectDispatchToProps)(AddressFiatView);
