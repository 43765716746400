import { clientApi, reportClient, ipRegistry, clientFormApi, clientGridApi,bankApi,uploadClient} from ".";
import { ApiControllers } from "./config";
import { appInsights } from "../layout/appinsights";
import CryptoJS from "crypto-js";
import moment from "moment";
const get = (url) => {
	return clientApi.get(+url);
};
const uploadFile = (file) => {
    return uploadClient.post("UploadFile", file);
}
 const ProfileImageSave = (obj) => {
    return clientApi.put(ApiControllers.customers + "Avatar", obj);
}
const changePassword = (obj) => {

    return clientApi.put(ApiControllers.customers + "ChangePWD", obj);
}
const getVerificationFields = (customerId) => {
	return clientApi.get(
		ApiControllers.master + `Verificationfields/${customerId}`
	);
};
const updateSecurity = (obj) => {
	return clientApi.put(ApiControllers.master + "UpdateSecurity", obj);
};
const getreports = (screenName) => {
	return clientApi.get(ApiControllers.reports + `GetReports/${screenName}`);
};
const getEmbidedReport = (url) => {
	return reportClient.get(ApiControllers.reports + url);
};
const saveBank = (obj) => {
	return clientApi.post(ApiControllers.Admin + "Bank", obj);
};
const enableorDisable = (obj) => {
	return clientApi.post(ApiControllers.Admin + "EnableorDisable", obj);
};
const getTransactionSearch = () => {
	return clientApi.get(ApiControllers.Admin + "Transcationlu");
};

const getDepositWithdraw = (depositId) => {
	return clientApi.get(
		ApiControllers.Admin +
		"CreateDepositandWithdrawl?depositorwithdrawlId=" +
		depositId
	);
};
const getSwap = (swapId) => {
	return clientApi.get(ApiControllers.Admin + "CreateSwap?SwapId=" + swapId);
};
const activeInactive = (obj) => {
	return clientApi.put(ApiControllers.master + `Status`, obj);
};
const getMember = () => {
	return clientApi.get(ApiControllers.customers + `App/Admin`);
};
const getdshKpis = (useremail) => {
	return clientApi.get(ApiControllers.dashboard + "/KPI");
};
const getdshcumulativePnl = (days) => {
	return clientApi.get(ApiControllers.dashboard + `/CumulativePNL/${days}`);
};
const getAssetNetwroth = (days) => {
	return clientApi.get(ApiControllers.dashboard + `/AssetsNetWorth/${days}`);
};
const getAssetAllowcation = (days) => {
	return clientApi.get(ApiControllers.dashboard + `/AssetAllocation/${days}`);
};
const getprofits = (days) => {
	return clientApi.get(ApiControllers.dashboard + `/Profits/${days}`);
};
const getdailypnl = (days) => {
	return clientApi.get(ApiControllers.dashboard + `/DailyPNL/${days}`);
};
const getWithdrawBalance = (withdraw) => {
	return clientApi.get(ApiControllers.dashboard + `/SweepWithdrawBalance/${withdraw}`);
};
const getManualGasBalance = (manualGas) => {
	return clientApi.get(ApiControllers.dashboard + `/SweepWithdrawBalance/${manualGas}`);
};
const getOmniBalance = (sweep) => {
	return clientApi.get(ApiControllers.dashboard + `/SweepWithdrawBalance/${sweep}`);
};
const getGasStationBalance = () => {
	return clientApi.get(ApiControllers.dashboard + `/GasStationBalance`);
};
const getKrakenBalance = (type) => {
	return clientApi.get(ApiControllers.dashboard + `/ExchangeBalance/${type}`);
};
const getFBTotalBalance = () => {
	return clientApi.get(ApiControllers.dashboard + `/FBTotalBalance`);
};
const getSignetBalance = () => {
	return clientApi.get(ApiControllers.dashboard + `/SignetBalance`);
};

const getPyrossBalance = () => {
	return clientApi.get(ApiControllers.pyrros + `/GetIbanBalance`);
};

const getReconcilDetails = (id) => {
	return clientFormApi.get(`/Reconcile/GetReconcileDetails/${id}`);
};
const getProvider = () =>{
	return clientFormApi.get(`/Reconcile/GetProviderLookup`);
}
const getCoinType = () =>{
	return clientFormApi.get(`/Reconcile/GetCoinLookup`);

}
const saveBalanceData = (obj) =>{
	return clientFormApi.post(`/Reconcile/SaveBalance`,obj);
}
const getBalanceValues = (id) =>{
	return clientFormApi.get(`/Reconcile/GetBalanceDetails/${id}`)
}
const refreshCall = (id, screen) => {
	return clientApi.get(
		ApiControllers.deposit + `UpdateInputAndOuputScores/${screen}/${id}`
	);
};
const getInfoVal = (id, type) => {
	return clientApi.get(
		ApiControllers.deposit + `GetScoreChainInfo/${id}/${type}`
	);
};

const downloadKyc = (Customerid) => {
	return clientApi.get(ApiControllers.customers + `DownloadFile/${Customerid}`);
};
const docUploade=(obj)=>{
	return clientApi.put(ApiControllers.customers + `Admin/Documents`,obj);
}
const getStates = (tabName,status) => {
	return clientApi.get(
		ApiControllers.common + `statechange/${tabName}/${status}`
	);
};

const getIpRegistery = () => {
	return ipRegistry.get("check?access_key=" + process.env.REACT_APP_IPREGISTERY_KEY);
};
const trackPageview = (obj) => {
	return appInsights.trackPageView({
		name: obj.Feature,
		properties: {
			Type: "Admin",
			Action: "Page view",
			Username: obj.userName,
			MemeberId: obj.id,
			Feature: obj.Feature,
			Remarks: obj.Remarks,
			Duration: 1,
			Url: window.location.href,
			FullFeatureName: obj.FullFeatureName,
		},
	});
};
const convertUTCToLocalTime = (dateString) => {
	return moment.utc(dateString).tz(process.env.REACT_APP_TIMEZONE);
};

const encryptValue = (msg, key) => {
	// msg = typeof msg == "object" ? JSON.stringify(msg) : msg;
	// let salt = CryptoJS.lib.WordArray.random(128 / 8);

	// let key1 = CryptoJS.PBKDF2(key, salt, {
	// 	keySize: 256 / 32,
	// 	iterations: 10,
	// });

	// let iv = CryptoJS.lib.WordArray.random(128 / 8);

	// let encrypted = CryptoJS.AES.encrypt(msg, key1, {
	// 	iv: iv,
	// 	padding: CryptoJS.pad.Pkcs7,
	// 	mode: CryptoJS.mode.CBC,
	// });
	// return salt.toString() + iv.toString() + encrypted.toString();
	const crypto = require('crypto');
	const iv = Buffer.alloc(16, 0); // Initialization vector (16 bytes of zero for simplicity)
	const cipher = crypto.createCipheriv('aes-256-cbc', Buffer.from(key, 'utf-8'), iv);
	let encrypted = cipher.update(msg, 'utf8', 'base64');
	encrypted += cipher.final('base64');
	return encrypted;
};
const getrecounicilkapiData = () =>{
	return clientFormApi.get(`/Reconcile/GetKPI`);
}
const getReconcileInfo = (id) => {
	return clientGridApi.get(
		ApiControllers.reconcile + `GetReconcileTransactionInfo/${id}`
	);
};
 const getAccountHolder=(customerId,type,currency)=>{
    return clientApi.get(ApiControllers.addressbook + `WithdrawPayeeLu/${customerId}/${type}/${currency}`);
}

const getPayeeLableLu=(customerId,currency)=>{
    return clientApi.get(ApiControllers.addressbook + `PayeeLableLu/${customerId}/${currency}`);
}
const confirmWithdrawl=(type,obj)=>{
   if(type=="fiat"){
	return clientApi.post(ApiControllers.withdraw +'Withdraw/Admin/Fiat/Confirm',obj);
   }else{
	return clientApi.post(ApiControllers.withdraw +'Crypto/Confirm/Admin',obj);
   }
}
const savewithdrawFiat=(obj)=>{
	return clientApi.post(ApiControllers.withdraw +'WithdrawTransaction',obj);
}
const getAddressBookLu=(customerId,type,cryptoCoin)=>{
	return clientApi.get(ApiControllers.addressbook + `Favourite/${customerId}/${type}/${cryptoCoin}`);
}
const confirmWithdrawCrypto=(Values,gridObj)=>{
	return clientApi.get(ApiControllers.withdraw + `Withdraw/Crypto/Confirm/${gridObj.customerId}/${gridObj.currency}/${gridObj.amount}/${Values.address}`);
}
const evolveSaveAdminTransfer=(obj)=>{
	return bankApi.put(ApiControllers.bank + `EvolveTransferStateChanges`,obj);
}
const pyrrosSaveAdminTransfer=(obj)=>{
	return bankApi.put(ApiControllers.bank + `PyrrosTransferStateChanges`,obj);
}
const getBankTransactions = (accNumber,bankName,status,page,pageSize) => {
	return bankApi.get(
		ApiControllers.bank + `BankAllTransactions/${accNumber}/${bankName}/${status}/${page}/${pageSize}`
	);
};
const getBankLus = () => {
	return bankApi.get(
		ApiControllers.bank + `GetAllBankLookup`
	);
};
const getBankLuStatus = (bank) => {
	return bankApi.get(
		ApiControllers.bank + `GetAllBankStatusLookup/${bank}`
	);
};
const getTransactionOptions = () => {
    return clientApi.get(ApiControllers.common + "ControlCodes?codeCategory=TransactionParty");
}
const isErrorDispaly = (objValue) => {
	if ((objValue.status > 400 && objValue.status < 500) && objValue.status != 401 && objValue.status != 480) {
		return "Something went wrong please try again!";
	} else {
		if (objValue.data && typeof objValue.data === "string") {
			return objValue.data;
		} else if (objValue.data && objValue.data.title && typeof objValue.data.title) {
			return objValue.data.title;
		} else if (
			objValue.originalError &&
			typeof objValue.originalError.message === "string"
		) {
			return objValue.originalError.message;
		} else {
			return "Something went wrong please try again!";
		}
	}
};
const uploadErrorDisplay = (objValue)=>{
	if ((objValue.status >= 400 && objValue.status < 500) && objValue.status != 401) {
		return "Something went wrong please try again!";
	} else {
		if ( objValue.title && typeof objValue.title) {
			return objValue.title;
		}   else {
			return "Something went wrong please try again!";
		}
	}
}

const resetPassword=(customerId)=>{
	return clientApi.get(ApiControllers.customers + `ResetPWD/${customerId}`);
}
const lockUnlock=(screen,obj)=>{
	if(screen=="WithdrawFiat" || screen =="Withdrawcrypto"){
		return clientApi.put(
			ApiControllers.withdraw + `${screen}/LockUnlock`,
			obj
		);
	}else{
		return clientApi.put(
			ApiControllers.deposit + `${screen}/LockUnlock`,
			obj
		);
	}
}
const getBankreports = (screenName) => {
	return clientApi.get(ApiControllers.reports + `GetReports/${screenName}`);
};

export default {
	convertUTCToLocalTime,
	get,
	getreports,
	getEmbidedReport,
	saveBank,
	enableorDisable,
	getTransactionSearch,
	getDepositWithdraw,
	getSwap,
	activeInactive,
	getMember,
	trackPageview,
	getdshKpis,
	getdshcumulativePnl,
	getAssetNetwroth,
	getAssetAllowcation,
	getprofits,
	getdailypnl,
	encryptValue,
	getIpRegistery,
	getGasStationBalance,
	getOmniBalance,
	getWithdrawBalance,
	getManualGasBalance,
	getKrakenBalance,
	getFBTotalBalance,
	getSignetBalance,
	getPyrossBalance,
	getReconcilDetails,
	getInfoVal,
	refreshCall,
	getProvider,
	getCoinType,
	saveBalanceData,
	getBalanceValues,
	getrecounicilkapiData,
	getReconcileInfo,
	getAccountHolder,
	getPayeeLableLu,confirmWithdrawl,savewithdrawFiat,getAddressBookLu,confirmWithdrawCrypto,evolveSaveAdminTransfer,pyrrosSaveAdminTransfer,
	uploadFile,
	ProfileImageSave,
	changePassword,
	getVerificationFields,
	updateSecurity,
	downloadKyc,
	isErrorDispaly,
	uploadErrorDisplay,
	docUploade,
	resetPassword,
	getStates,
	lockUnlock,
	getBankTransactions,
	getBankLus,
	getBankLuStatus,
	getTransactionOptions,
	getBankreports
};
