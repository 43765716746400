import React, { useEffect, useState } from 'react';
import { Form, Row, Col, Input, Button, Select, message, Alert, DatePicker } from 'antd';
import { useHistory } from "react-router";
import Loader from '../loader.component';
import { connect } from 'react-redux';
import { handleCurrency, handleBank } from '../../reducers/bankReducer';
import { saveBank, getBank, getIBANData,getReceiveOptions } from './api';
import apiCalls from '../../api/apiCalls';
import { publishShowActions } from '../grid.component/subscribir';
import { setCurrentAction } from '../../reducers/actionsReducer';
import { validateContentRule } from '../../utils/custom.validator';
import moment from 'moment'
import Moment from 'react-moment';
import VerificationsModal from './verificationsModal';
import config from "../../config";
const AddBank = (props) => {
    const useBankRef = React.createRef();
    const [form] = Form.useForm();
    const history = useHistory();
    const [bankObject, setBankObject] = useState({});
    const [swiftCode, setSwiftCode] = useState(false);
    const [type] = useState(props.match.params.type);
    const [errorMsg, setErrorMsg] = useState(null);
    const [loadingBtn, setBtnLoading] = useState(false);
    const [isCheck, setIsCheck] = useState(false);
    const [bankDateObject, setBankDateObject] = useState({});
    const [bankCurrency, setBankCurrency] = useState({})
    const [isVerificationsComplete , setIsVerificationsComplete] = useState(false)
    const [verificationsModalIsOpen, setVerificationsModalIsOpen] = useState(false)
    const [receiveOptions,setIsReceiveOptions]=useState([]);
    const [seletedReceiveOption,setIsSelectReceiveOption]=useState(null);
    const [transactionOptionData,setIsTransactionOptionData]=useState([]);
    const [selectTransactionOption,setIsSelectTransaction]=useState(null);
    const { TextArea } = Input;
    useEffect(() => {
        if (form.getFieldValue('currencyCode') && props.bankStore.currency.data?.length !== 0) {
            handleChange(form.getFieldValue('currencyCode'), true)
        }
        getTransactionOptionsData()
    }, [props.bankStore?.currency?.data]);//eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        getReceiveOption()
        publishShowActions(false);
        props.fetchCurrencyData();
        if (props.match.params.id !== "00000000-0000-0000-0000-000000000000") {
            props.setAction(type !== 'disabled' ? 'edit' : 'view');
            loadData();
        } else {
            props.setAction('add');
        }

    }, []);//eslint-disable-line react-hooks/exhaustive-deps

    const getTransactionOptionsData=async()=>{
        let res = await apiCalls.getTransactionOptions();
        if(res.ok){
            setIsTransactionOptionData(res.data);           
        }else{
            setErrorMsg(apiCalls.isErrorDispaly(res));
        }
    }
    const handleBankAccountChanges = async (e) => {
        if (
            typeof e.target.value !== "undefined" &&
            e.target.value !== null &&
            e.target.value !== ""
        ) {
            let benficiaryAccountNumber = e.target.value;
            let cleanStr = benficiaryAccountNumber.trim();
            let response = await getIBANData(cleanStr);
            if (response.ok) {
                if (
                    typeof response.data !== "undefined" &&
                    response.data !== null &&
                    response.data !== ""
                ) {
                    let _data = {};
                    _data.city = response.data.city;
                    _data.country = response.data.country;
                    _data.routingNumber = response.data.routingNumber;
                    _data.state = response.data.state;
                    form.setFieldsValue({ ..._data });
                }
                setErrorMsg(null);
            } else {
                setErrorMsg(apiCalls.isErrorDispaly(response));
               window.scrollTo(0, 0)
            }
        }
        else {
            form.setFieldsValue({
                city: null, country: null, routingNumber: null, state: null
        });
        }
    };
    const getReceiveOption=async()=>{
   let res = await getReceiveOptions();
   if (res.ok){
    setIsReceiveOptions(res.data);
   }else{
    setIsReceiveOptions(null);
    setErrorMsg(apiCalls.isErrorDispaly(res));
   }
    }
    const loadData = async () => {
        let response = await getBank(props.match.params.id);
        if (response.ok) {
            setErrorMsg(null)
            setIsCheck(response.data.isDefaultBank)
            setBankObject(response.data)
            bindEditableData(response.data)
            setIsSelectReceiveOption(response?.data?.receivingOptions);
            setIsSelectTransaction(response.data?.transactionParty);
            form.setFieldsValue({ ...response.data })
            if (props.bankStore?.currency?.data?.length !== 0) {
                handleChange(response.data.currencyCode, true)
            }
        }else{
            setErrorMsg(apiCalls.isErrorDispaly(response));
        }
    }

    const backToBanks = () => {
        setCurrentAction(null);
        history.push({
            pathname: '/banks'
        });
    }
    const bindEditableData = (obj) => {
        obj.dateOfIncorporation = obj.dateOfIncorporation ? moment(moment(new Date(obj.dateOfIncorporation))) : "";
        setBankDateObject({ ...obj });
        form.setFieldsValue({ ...obj });
    };
    const handleChanges = (prop, val) => {
        let v = prop.currentTarget ? prop.currentTarget.value : val;
        bankDateObject[prop] = val ? v : "";
        setBankDateObject({ ...bankDateObject });
    }

    const handleChange = (id, notClearCode) => {
        if (props.bankStore?.currency?.length !== 0 && props.bankStore?.currency?.data?.length !== 0) {
            let cur = props.bankStore?.currency?.data;
            let index = cur.findIndex((o) => o.code === id);
            let val = cur[index].code;
            let com = (val === "EUR");
            setSwiftCode(com);
            setBankCurrency(val);
            if (!notClearCode) {
                let values = form.getFieldsValue()
                values["benficiarySwiftCodeorBICCode"] = "";
                form.setFieldsValue({ 
                    city: null, country: null, routingNumber: null, state: null,
                    benficiaryBankName: '',
                    benficiaryBankAddress: '', benficiaryAccountName:'',benficiaryAccountNumber: '',benficiaryAccountAddress:'',
                    network:'',benficiarySwiftCodeorBICCode:'',referenceNo:'',email:'',phoneNo:'',line1:'',
                    line2:'',postalCode:'',dateOfIncorporation:''
                });
            }
        }
    }
    const saveBankInfo = async (values) => {
        setBtnLoading(true);
        setErrorMsg(null);
        let bank = props.bankStore.currency.data;
        let index = bank.findIndex(o => o.code === values.currencyCode);
        values.benficiaryAccountAddress = apiCalls.encryptValue(values.benficiaryAccountAddress, props.userConfig?.sk);
        values.benficiaryAccountName = apiCalls.encryptValue(values.benficiaryAccountName, props.userConfig?.sk);
        values.benficiaryAccountNumber = apiCalls.encryptValue(values.benficiaryAccountNumber, props.userConfig?.sk);
        values.id = props.match.params.id;
        values.currencyCode = bank[index].code;
        values.customerId = props.userConfig?.id;
        values.dateOfIncorporation = moment(values.date).format('YYYY-MM-DD');
        values.isDefaultBank = isCheck;
        values.info = JSON.stringify(props.trackAuditLogData);
        values.createdBy = props.userConfig?.userName;
        values.modifiedby=props.match.params.id !== "00000000-0000-0000-0000-000000000000" && props.userConfig?.userName;
        let response = await saveBank(values, props.match.params.id);
        if (response.ok) {
            setCurrentAction(null);
            message.destroy()
            message.success({
                content: 'Bank details saved successfully',
                className: 'custom-msg',
                duration: 4
            });
           
            history.push('/banks');
            setErrorMsg(null);
            setBtnLoading(false);
        } else {
            setBtnLoading(false);
            setErrorMsg(apiCalls.isErrorDispaly(response));
            window.scrollTo(0, 0)
        }

    };

    const handlebankChange = (e) => {
        setIsCheck(e.target.checked);
    };

    const handleReceiveOption=(e)=>{
        setIsSelectReceiveOption(e)
        form?.setFieldsValue({receivingComment:null});
    }
    const handleTransactionOption=(e)=>{
        setIsSelectTransaction(e);
        form?.setFieldsValue({transactionPartyRemarks:null});
    }
    const ReceiveOptions = receiveOptions?.map((item, idx) => <option key={idx} title="" value={item.code}>{item.code}</option>)
    const options = props.bankStore.currency.data?.map((item, idx) => <option key={idx} title="" value={item.code}>{item.code}</option>)
    const transactionOptions = transactionOptionData?.map((item, idx) => <option key={idx} title="" value={item.code}>{item.code}</option>)
    return (
        <>
        <div ref={useBankRef}></div>
        <div>
            {props.bankStore?.currency?.error?.message === "Request failed with status code 401" && <Alert type="error" showIcon description="You are not authorized. Please contact Administrator." className="mb-8" />}
            {props.bankStore.currency.loading && <Loader />}
            {errorMsg !== undefined && errorMsg !== null && (
                <Alert className="w-100 mb-16" type="error" showIcon description={errorMsg} />
            )}
            <Form
                initialValues={bankObject}
                form={form}
                name="advanced_search"
                className="ant-advanced-search-form"
                onFinish={(verificationsModalIsOpen && isVerificationsComplete) ? saveBankInfo : () => { setVerificationsModalIsOpen(true) } }
                autoComplete="off"
            >
                <Row gutter={24}>
                    <Col xs={24} sm={24} md={12} lg={8} xxl={6}>
                        <Form.Item
                            name="currencyCode"
                            label="Currency"
                            className="input-label"
                            rules={[
                                {
                                    required: true,
                                    message: 'Is required',
                                },
                            ]}
                        >
                            <Select
                                className={`cust-input ${(type === 'disabled'|| props.match.params.id !== "00000000-0000-0000-0000-000000000000")&&"cust-input-disabled"}`}
                                onSelect={(e) => handleChange(e)}
                                placeholder="Select Currency"
                                disabled={((type === 'disabled' ? true : false) || (props.match.params.id !== "00000000-0000-0000-0000-000000000000" ? true : false))}
                            >

                                {options}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={8} xxl={6}>
                        <Form.Item
                            name="benficiaryBankName"
                            label="Beneficiary Bank Name"
                            className="input-label"
                            rules={[
                                {
                                    required: true,
                                    message: 'Is required'
                                },
                                {
                                    whitespace: true,
                                    message: 'Is required'
                                }, {
                                    validator: validateContentRule
                                }
                            ]}
                        >
                            <Input placeholder="Beneficiary Bank Name" disabled={(type === 'disabled') || (props.match.params.id !== "00000000-0000-0000-0000-000000000000")} className="cust-input" maxLength={30} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={8} xxl={6}>
                        <Form.Item
                            name="benficiaryBankAddress"
                            label="Beneficiary Bank Address"
                            className="input-label"
                            rules={[
                                {
                                    required: true,
                                    message: 'Is required'
                                },
                                {
                                    whitespace: true,
                                    message: 'Is required'
                                }, {
                                    validator: validateContentRule
                                }
                            ]}
                        >
                            <Input placeholder="Beneficiary Bank Address" disabled={type === 'disabled'} className="cust-input" maxLength={50} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={8} xxl={6}>
                        <Form.Item
                            name="benficiaryAccountName"
                            label="Beneficiary Account Name"
                            className="input-label"
                            rules={[
                                {
                                    required: true,
                                    message: 'Is required'
                                },
                                {
                                    whitespace: true,
                                    message: 'Is required'
                                }, {
                                    validator: validateContentRule
                                   }

                            ]}
                        >
                            <Input placeholder="Beneficiary Account Name" disabled={type === 'disabled'} className="cust-input" maxLength={30} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={8} xxl={6}>
                        <Form.Item
                            name="benficiaryAccountNumber"
                            label="Beneficiary Account Number/IBAN"
                            className="input-label"
                            required
                            rules={[                               
                                 {
                                    validator: (_, value) => {
                                        if (!value) {
                                            return Promise.reject("Is required");
                                        } else if ((value.length < 5 || value.length > 30) || (value && !/^[A-Za-z0-9]+$/.test(value))) {
                                    
                                            return Promise.reject("Invalid Account Number");
                                        } else {
                                            return validateContentRule(_, value)
                                        }
                                    },
                                }
                            ]}
                        >
                            <Input
                                placeholder="Beneficiary Account Number/IBAN"
                                disabled={type === 'disabled'} className="cust-input" maxLength={30}
                                onChange={(e) => bankCurrency == 'EUR' ? handleBankAccountChanges(e) : ''} />
                        </Form.Item>
                    </Col>

                    <Col xs={24} sm={24} md={12} lg={8} xxl={6}>
                        <Form.Item
                            name="benficiaryAccountAddress"
                            label="Beneficiary Account Address"
                            className="input-label"
                            rules={[
                                {
                                    required: true,
                                    message: 'Is required',
                                },
                                {
                                    whitespace: true,
                                    message: 'Is required'
                                }, {
                                    validator: validateContentRule
                                }
                            ]}
                        >
                            <Input placeholder="Beneficiary Account Address" disabled={type === 'disabled'} className="cust-input" maxLength={50} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={8} xxl={6}>
                        <Form.Item
                            name="network"
                            label="Beneficiary Network"
                            className="input-label"
                            rules={[
                                {
                                    validator: validateContentRule
                                }
                            ]}
                        >
                            <Input placeholder="Beneficiary Network" disabled={type === 'disabled'} className="cust-input" maxLength={10} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={8} xxl={6}>
                        <Form.Item
                            name="benficiarySwiftCodeorBICCode"
                            label={`${swiftCode === true ? "BIC" : "Swift"} Code`}
                            className="input-label"
                            rules={[
                                {
                                    required: true,
                                    message: 'Is required',
                                },
                                {
                                    whitespace: true,
                                    message: 'Is required'
                                }, {
                                    validator: validateContentRule
                                }
                            ]}
                        >
                            <Input placeholder={`${swiftCode === true ? "BIC" : "Swift"} Code`} disabled={type === 'disabled'} className="cust-input" maxLength={20} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={8} xxl={6}>
                        <Form.Item
                            name="routingNumber"
                            label="Routing Number"
                            className="input-label"
                            rules={[
                                {
                                    required: isCheck,
                                    message: 'Is required',
                                },
                                {
                                    validator: validateContentRule
                                }
                            ]}
                        >
                            <Input placeholder="Routing Number" disabled={type === 'disabled'} className="cust-input" maxLength={10} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={8} xxl={6}>
                        <Form.Item
                            name="referenceNo"
                            label="Bank Reference No"
                            className="input-label"
                            rules={[
                                {
                                    required: isCheck,
                                    message: 'Is required',
                                },
                                {
                                    validator: validateContentRule
                                }
                            ]}
                        >
                            <Input placeholder="Bank Reference No" disabled={type === 'disabled'} className="cust-input" maxLength={20} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={8} xxl={6}>
                        <Form.Item
                            name="email"
                            label="Email"
                            className="input-label"
                            required={isCheck}
                            rules={[
                                {
                                    validator: (_, Val) => {
                                        if (!Val) {
                                            if (isCheck) {
                                                return Promise.reject("Is required");
                                                }
                                        } else if (Val.length < 5 || Val.length > 50) {
                                            return Promise.reject("Invalid Email");
                                        
                                        } else if (!(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,100}$/.test(Val))) {
                                            return Promise.reject("Invalid Email");
                                        } else {
                                            return validateContentRule(_, Val)
                                        }
                                        return Promise.resolve();
                                    }
                                }
                            ]}
                        >
                            <Input placeholder="Email" disabled={type === 'disabled'} className="cust-input" maxLength={30} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={8} xxl={6}>
                        <Form.Item
                            name="phoneNo"
                            label="Phone No"
                            className="input-label"
                            required={isCheck}
                            rules={[
                                {
                                    validator: (_, Val) => {
                                        if (!Val) {
                                            if (isCheck) {
                                            return Promise.reject("Is required");
                                            }
                                        } else if (Val.length < 5 || Val.length > 20) {

                                            return Promise.reject("Invalid phone number");
                                        } else if (!(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/.test(Val))) {
                                    
                                            return Promise.reject("Invalid phone number");
                                        } else {
                                            return validateContentRule(_, Val)
                                        }
                                          return Promise.resolve();
                                    }
                                }, 
                            ]}
                        >
                            <Input placeholder="PhoneNo" disabled={type === 'disabled'} className="cust-input" maxLength={20} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={8} xxl={6}>
                        <Form.Item
                            name="line1"
                            label="Line1"
                            className="input-label"
                            rules={[
                                {
                                    required: isCheck,
                                    message: 'Is required',
                                },
                                {
                                    validator: validateContentRule
                                }
                            ]}
                        >
                            <Input placeholder="Line1" disabled={type === 'disabled'} className="cust-input" maxLength={30} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={8} xxl={6}>
                        <Form.Item
                            name="line2"
                            label="Line2"
                            className="input-label"
                            rules={[
                                {
                                    required: false,
                                    message: 'Is required',
                                },
                                {
                                    validator: validateContentRule
                                }
                            ]}
                        >
                            <Input placeholder="Line2" disabled={type === 'disabled'} className="cust-input" maxLength={30} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={8} xxl={6}>
                        <Form.Item
                            name="city"
                            label="City"
                            className="input-label"
                            rules={[
                                {
                                    required: isCheck,
                                    message: 'Is required',
                                },
                                {
                                    validator: validateContentRule
                                }
                            ]}
                        >
                            <Input placeholder="City" disabled={type === 'disabled'} className="cust-input" maxLength={30} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={8} xxl={6}>
                        <Form.Item
                            name="state"
                            label="State"
                            className="input-label"
                            rules={[
                                {
                                    required: isCheck,
                                    message: 'Is required',
                                },
                                {
                                    validator: validateContentRule
                                }
                            ]}
                        >
                            <Input placeholder="State" disabled={type === 'disabled'} className="cust-input" maxLength={30} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={8} xxl={6}>
                        <Form.Item
                            name="country"
                            label="Country"
                            className="input-label"
                            rules={[
                                {
                                    required: isCheck,
                                    message: 'Is required',
                                },
                                {
                                    validator: validateContentRule
                                }
                            ]}
                        >
                            <Input placeholder="Country" disabled={type === 'disabled'} className="cust-input" maxLength={30} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={8} xxl={6}>
                        <Form.Item
                            name="postalCode"
                            label="Postal Code"
                            className="input-label"
                            rules={[
                                {
                                    required: isCheck,
                                    message: 'Is required',
                                },
                                {
                                    validator: validateContentRule
                                }
                            ]}
                        >
                            <Input placeholder="Postal Code" disabled={type === 'disabled'} className="cust-input" maxLength={30} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={8} xxl={6}>
                        <Form.Item
                            name="dateOfIncorporation"
                            className="input-label"
                            label="Date Of Incorporation"
                            rules={
                                [{
                                    required: isCheck,
                                    message: "Is required"
                                }]}>
                            <DatePicker
                                format={config?.dateFormates?.dateFormate}
                                className="cust-input"
                                placeholder="Date Of Incorporation"
                                disabled={type === 'disabled' ? true : false}
                                onChange={(value) =>
                                    handleChanges("dateOfIncorporation", value)
                                }
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={8} xxl={6}>
                        <Form.Item
                            name="receivingOptions"
                            label="Receiving Options"
                            className="input-label"
                           
                        >
                            <Select
                                className={`cust-input ${type === 'disabled'&&"cust-input-disabled"}`}
                                onSelect={(e) => handleReceiveOption(e)}
                                placeholder="Select Receiving Option"
                               disabled={type === 'disabled'}
                          >
                                {ReceiveOptions}
                            </Select>
                        </Form.Item>
                    </Col>
                   {seletedReceiveOption === "Others" && <Col xs={24} sm={24} md={12} lg={8} xxl={8}>
                        <Form.Item
                            name="receivingComment"
                            label="Remarks"
                            className="input-label"
                            rules={[
                                {
                                    required: true,
                                    message: 'Is required',
                                },
                                {
                                    validator: validateContentRule
                                }
                            ]}
                        >
                            <TextArea
                            placeholder="Remarks"
                            showCount
                            maxLength={100}
                            rows={2}
                            disabled={type === 'disabled'}
                        />
                        </Form.Item>
                    </Col>}
                    <Col xs={24} sm={24} md={12} lg={8} xxl={6}>
                        <Form.Item
                            name="transactionParty"
                            label="Transaction Party"
                            className="input-label"
                            rules={[
                                {
                                    required: true,
                                    message: 'Is required',
                                },
                                {
                                    validator: validateContentRule
                                }
                            ]}
                        >
                            <Select
                                className={`cust-input ${type === 'disabled'&&"cust-input-disabled"}`}
                                onSelect={(e) => handleTransactionOption(e)}
                                placeholder="Select Transaction Party"
                               disabled={type === 'disabled'}
                          >
                                {transactionOptions}
                            </Select>
                        </Form.Item>
                    </Col>
                   {selectTransactionOption === "Others" && <Col xs={24} sm={24} md={12} lg={8} xxl={8}>
                        <Form.Item
                            name="transactionPartyRemarks"
                            label="Remarks"
                            className="input-label"
                            rules={[
                                {
                                    required: true,
                                    message: 'Is required',
                                },
                                {
                                    validator: validateContentRule
                                }
                            ]}
                        >
                            <TextArea
                            placeholder="Remarks"
                            showCount
                            maxLength={100}
                            rows={2}
                            disabled={type === 'disabled'}
                        />
                        </Form.Item>
                    </Col>}
                    <Col xs={24} sm={24} md={12} lg={6} xxl={4}
                        style={{ "padding-left": '8px', margin: "16px 8px 0" }}
                    >
                        <div className="d-flex align-center my-16">
                            <label className="text-center custom-checkbox">
                                <input
                                    name="isCustomerDocument"
                                    type="checkbox"
                                    className="grid_check_box"
                                    onChange={(e) => handlebankChange(e)}
                                    checked={isCheck}
                                    disabled={type === "disabled" ? true : false}
                                />
                                <span></span>
                            </label>
                            <span className="ml-8">Is SuisseBase Digital Transfer</span>
                        </div>
                    </Col>
                </Row>
                <Col xs={24} sm={24} md={24} lg={24} xxl={24}>
                    {(type !== 'disabled' && verificationsModalIsOpen) && 
                    <VerificationsModal
                        verificationsComplete={() => {setIsVerificationsComplete(true)}}
                        visible={verificationsModalIsOpen}
                        closeModal={()=>{setVerificationsModalIsOpen(false)}}
                        onConfirmButton={()=>{form.submit()}}
                        loadingBtn={loadingBtn}
                    /> }
                </ Col>
                <Row gutter={24}>
                                {bankObject?.createdBy && (
                                    <Col xs={24} sm={24} md={12} lg={12} xxl={12}>
                                        <div className="record-history d-flex align-center ml-0">
                                        <div className="record-history-text">
                                            {bankObject?.createdBy} created this on {"  "}
                                            <Moment format={config?.dateFormates?.dateTimeFormate}>
                                                {bankObject?.createdDate
                                                    ? apiCalls.convertUTCToLocalTime(
                                                        bankObject?.createdDate
                                                    )
                                                    : bankObject?.createdDate}
                                            </Moment></div>
                                        </div>
                                    </Col>
                                )}

                                {bankObject?.modifiedBy && (
                                    <Col xs={24} sm={24} md={12} lg={12} xxl={12}>
                                        <div className="record-history d-flex align-center ml-0">
                                            <div className="record-history-text">
                                                {bankObject?.modifiedBy} modified this on {" "}
                                                <Moment format={config?.dateFormates?.dateTimeFormate}>
                                                    {bankObject?.modifiedDate
                                                        ? apiCalls.convertUTCToLocalTime(
                                                            bankObject?.modifiedDate
                                                        )
                                                        :bankObject?.modifiedDate}
                                                </Moment>
                                            </div>
                                        </div>
                                    </Col>
                                )}
                            </Row>
                <div className="text-right mt-24">
                {
                (type !== 'disabled') &&
                    <Button type="primary" className="primary-btn"
                        onClick={()=>
                            form.submit()
                        }
                    >
                        Save
                    </Button>}
                    <Button
                        type="primary"
                        className="primary-btn cancel-btn"
                        style={{ margin: '0 8px' }}
                        onClick={backToBanks}
                    >
                        Cancel
                    </Button>
                </div>
            </Form>
        </div>
        </>
    );
}

const connectStateToProps = ({ bankStore, userConfig }) => {
    return { bankStore, userConfig: userConfig.userProfileInfo, trackAuditLogData: userConfig.trackAuditLogData }
}
const connectDispatchToProps = dispatch => {
    return {
        fetchCurrencyData: () => {
            dispatch(handleCurrency())
        },
        fetchBankData: (id) => {
            dispatch(handleBank(id))
        },
        setAction: (val) => {
            dispatch(setCurrentAction(val))
        },
    }
}

export default connect(connectStateToProps, connectDispatchToProps)(AddBank);