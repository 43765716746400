import { Alert, Button, Col, Form, Input, Modal, Row, Tooltip, message } from "antd";
import React from "react";
import NumberFormat from "react-number-format";
import List from "../../grid.component";
import Loader from "../../loader.component";
import Info from "../../info/Info";
import { introducerSave } from "../api";
import apiCalls from "../../../api/apiCalls";
import { connect } from "react-redux";

class IntroducerDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openModel: false,
      btnLoading: false,
      errorMsg: null,
      modalErrorMsg:null,
      loader: false,
      check: null,
      checkboxSelection: [],
      selectedObj: {},
      gridData: {},
      selection: [],
    };
    this.formRef = React.createRef();
    this.gridRef = React.createRef();
    this.edit = this.edit.bind(this);
    this.gridUrl = process.env.REACT_APP_GRID_API + "Customer/Introducers";
  }

  handleSelectionChange = (prop, e) => {
		this.formref?.current?.resetFields();
		this.setState({ ...this.state, checkboxSelection: [] })
		const rowObj = prop.dataItem;
		let { selection,checkboxSelection } = this.state;
		let idx = selection.indexOf(rowObj?.id);
		if (idx > -1) {
			selection.splice(idx, 1);
			checkboxSelection?.splice(idx, 1)
		} else {
			selection.push(rowObj?.id);
			checkboxSelection?.push(rowObj)
		}
		this.setState({
			...this.state,
			selectedObj:rowObj,
			selection,
			errorMsg: null,
		   checkboxSelection
		});
		this.formref?.current?.resetFields();
	};
	


  edit = () => {
    if (this.state?.selection.length == 0) {
			this.setState({ ...this.state,  selection: [],checkboxSelection:[], openModel:false, errorMsg: "Please select the one record" });
			return
		}
    else if (this.state?.selection.length > 1) {
			this.setState({ ...this.state,  selection: [], checkboxSelection:[],openModel:false, errorMsg: "Please select only one record" });
			return
		}
    this.setState({openModel:true})
  };

  handleModalClose = () => {
    this.setState({
      openModel: false,
      modalErrorMsg: null,
      selection: [],
      checkboxSelection:[],
      errorMsg:null
    });
  };
  onSubmit = async (values) => {
    const { onBoardingFee, transactionFee } = values;
    if (parseFloat(onBoardingFee) === 0 || parseFloat(transactionFee) === 0) {
      this.setState({ ...this.state,modalErrorMsg: " Amount must be greater than zero." });
        return;
    }
    else if (onBoardingFee.toString().startsWith(".")||transactionFee.toString().startsWith(".")) {

      this.setState({ ...this.state,modalErrorMsg:  "Please enter a valid amount." });
        return;
    }
    else if (
      ((onBoardingFee > 99 || transactionFee > 99))
    ) {
      this.setState({
        ...this.state,
        modalErrorMsg: "Fees will be between 1-99%",
      });
      return;
    }
    
     this.setState({ errorMsg: null, loader: true, btnLoading: true });

    const obj = {
      "id": this.state?.selectedObj?.id,
      "transactionFee": parseInt(values?.transactionFee),
      "onBoardingFee": parseInt(values?.onBoardingFee),
      "ModifiedBy":this.props?.userConfig?.userName
    };
   try{
     const res = await introducerSave(obj);
     if (res.ok) {
      this.gridRef?.current?.refreshGrid();
      this.setState({ openModel: false, loader: false, btnLoading: false });
      this.props.history?.push(`/introducers`);
       this.setState({  selection: [] });
      message.success({
        content: "Record successfully updated.",
        className: "custom-msg",
        duration: 4,
      });
      this.handleModalClose()
     } else {
      this.setState({
         loader: false,
        btnLoading: false,
        modalErrorMsg:null
      });
      this.setState({ ...this.state,modalErrorMsg : apiCalls?.isErrorDispaly(res)});
     }
    }
    catch(error){
      this.setState({
        loader: false,
       btnLoading: false,
       errorMsg: error,
       modalErrorMsg:null
     });
    }
  };
  onActionClick = (key) => {
    const action = {
      edit: "edit",
    };
    this[action[key]]();
  };
  render() {
    const {errorMsg, loader, btnLoading,modalErrorMsg } = this.state;
    const gridColumns = [
      {
        field: "",
        title: "",
        width: 50,
        locked: true,
        customCell: (props) => (
          <td className="text-center">
            <label className="text-center custom-checkbox">
              <input
                id={props.dataItem.id}
                name="check"
                type="checkbox"
                checked={this.state.selection.indexOf(props.dataItem.id) > -1}
			       		onChange={(e) => this.handleSelectionChange(props, e)}
              />
              <span></span>
            </label>
          </td>
        ),
      },
      { field: "introducerName", title: "Introducer Name", filter: true, width: 120 },
      { field: "referredClientName", title: "Referred Client Name", filter: true, width: 160 },
      { field: "state", title: "Client Status", filter: true, width: 120 },
      { field: "transactionFee", title: "Transaction Fees (%)", filter: true, width: 160, dataType: "number", filterType: "numeric" },
      { field: "onBoardingFee", title: "Onboarding Fees (%)", filter: true, width: 160, dataType: "number", filterType: "numeric" },
    ];

    return (
      <>
        {errorMsg && <Alert className="w-100 mb-16" type="error" showIcon description={errorMsg} />}
        <div className="text-right mb-8">
        </div>
        <Modal
          title="Edit"
          visible={this.state.openModel}
          width={850}
          className="crypto-list bank-modal"
          destroyOnClose
          closeIcon={
            <Tooltip title="Close" onClick={this.handleModalClose}>
              <span className="icon md x c-pointer" />
            </Tooltip>
          }
          footer={null}
        >
          <div>
            {loader ? (
              <Loader />
            ) : (
              <>
                {modalErrorMsg && <Alert className="w-100 mb-16" type="error" showIcon description={modalErrorMsg} />}
                <Form
                  ref={this.formRef}
                  className="ant-advanced-search-form"
                  onFinish={this.onSubmit}
                  autoComplete="off"
                   initialValues={this.state?.checkboxSelection[0]}
                >
                  <Row gutter={24}>
                    <Col xs={24} sm={24} md={12} lg={12} xxl={12}>
                      <Form.Item
                        name="introducerName"
                        label="Introducer Name"
                        className="input-label"
                      >
                        <Input placeholder="Introducer Name" className="cust-input" disabled />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xxl={12}>
                      <Form.Item
                        name="referredClientName"
                        label="Referred Client Name"
                        className="input-label"
                      >
                        <Input placeholder="Referred Client Name" className="cust-input" disabled />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xxl={12}>
                      <Form.Item
                        name="transactionFee"
                        label="Transaction Fees %"
                        className="input-label"
                        rules={[{ required: true, message: "Is required" },
                        ]}
                      >
                        <NumberFormat
                          placeholder="Transaction Fees"
                          className="cust-input"
                          decimalScale={0}
                          thousandSeparator={true}
                          customInput={Input}
                          isAllowed={(values) => {
                            const { floatValue } = values;
                            return floatValue === undefined || (floatValue >= 0 && floatValue <= 99);
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xxl={12}>
                      <Form.Item
                        name="onBoardingFee"
                        label="Onboarding Fees %"
                        className="input-label"
                        rules={[{ required: true, message: "Is required" },
                        ]}
                      >
                        <NumberFormat
                          placeholder="Onboarding Fees"
                          className="cust-input"
                          decimalScale={0}
                          thousandSeparator={true}
                          customInput={Input}
                          isAllowed={(values) => {
                            const { floatValue } = values;
                            return floatValue === undefined || (floatValue >= 0 && floatValue <= 99);
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Info Id={this.state?.selectedObj?.id} Createdby={this.state?.selectedObj?.createBy} CreatedDate={this.state?.selectedObj?.createDate} ModifiedDate={this.state?.selectedObj?.modifiedDate} Modifiedby={this.state?.selectedObj?.modifiedBy} selectedId={ this.state?.selectedObj?.id}screenName={"Introducer"} />
                  <Form.Item className="mb-0">
            <div className="text-right">
              <Button type="primary" className="primary-btn cancel-btn mr-8" onClick={this.handleModalClose}>
                Cancel
              </Button>
              <Button type="primary" key="submit" className="primary-btn" htmlType="submit" loading={btnLoading} >
                Save
              </Button>
            </div>
          </Form.Item>
                 
                </Form>
                </>
             )} 
          </div>
        </Modal>
        <List
          showActionBar={true}
          onActionClick={(key) => this.onActionClick(key)}
          url={this.gridUrl}
          ref={this.gridRef}
          key={this.gridUrl}
          columns={gridColumns}
          showExcelExport={true}
          className="customer-table-header"
        />
      </>
    );
  }
}

const connectStateToProps = ({userConfig}) => {
	return {
		userConfig: userConfig?.userProfileInfo
	};
};
export default connect(connectStateToProps)(IntroducerDetails);
